@import "../../../../scss/variables.scss";
$bp: 670px;

.profiletab-root-container {
  display: flex;
  width: 100%;
  justify-content: center;
  overflow-y: auto;
  padding: 0px 20px;

  @media (max-width: $bp) {
    padding: 0px;
  }

  .profile-view-container-1 {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #eef0fa33;
    align-items: center;
    padding: 30px 0px;
    justify-content: flex-end;
    gap: 45px;
    .avatar-div-container {
      display: flex;
      align-items: flex-end;
      .default-user-item {
        height: 200px;
        width: 200px;
        border-radius: 50%;
        object-fit: cover;
      }
      .edit-root-container {
        cursor: pointer;
        height: 44px;
        width: 44px;
        border-radius: 50%;
        background: linear-gradient(180deg, #c8a86b 0%, #725e3a 100%);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: -44px;
        margin-bottom: 4px;
      }
    }
    .right-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      @media (max-width: $bp) {
        width: calc(100% - 40px);
      }

      .user-detail-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        row-gap: 15px;
        padding: 0px 25px 0px 35px;
        width: 250px;
        height: 150px;

        @media (max-width: $bp) {
          width: 100%;
          padding: 0px;
        }

        .progress-root-container {
          border-radius: 10px;
          height: 3px;
          background: $main-11;
          margin: 10px 0px;
          .progress-bar {
            height: 3px;
            border-radius: 10px;
            background: $main-2;
            box-shadow: 0px 0px 10px $main-2;
            opacity: 1;
          }
        }
        .user-name-span {
          font-size: $font-size-22;
          line-height: 27px;
          color: $main-1;
          font-weight: $font-weight-2;
        }
        .notes-container {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 5px 0px;
          .music-note-image {
            height: 10px;
            width: 7px;
          }
          .note-score-span-container {
            font-size: $font-size-small;
            line-height: 15px;
            color: $main-1;
            padding-left: 4px;
          }
        }
        .setting-button-container {
          background-color: $main-11;
          border-radius: 6px;
          cursor: pointer;
          height: 44px;
          display: flex;
          justify-content: center;
          align-items: center;
          .setting-span-container {
            display: flex;
            justify-content: center;
            align-items: center;
            .edit-profile-span {
              padding-left: 8px;
              font-size: $font-size-small;
              font-weight: $font-weight-1;
              line-height: 15px;
              text-align: center;
              text-transform: uppercase;
              color: $main-1;
            }
          }
        }
        .social-button-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .follower-button-container {
            background-color: $main-11;
            border-radius: 6px;
            width: calc(50% - 10px);
            cursor: pointer;
            .follower-span-container {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 8px;
              flex-direction: column;
              height: 44px;
              .span-text {
                opacity: 0.5;
                text-align: center;
                align-items: center;
                line-height: 15px;
                font-size: $font-size-small;
              }
              .span-value-text {
                color: $main-1;
                text-align: center;
                align-items: center;
                font-size: $font-size-normal;
                line-height: 17px;
              }
            }
          }
        }
        .div-container-2 {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;
          .rank-container {
            display: flex;
            flex-direction: column;
            .nextrank-span-text {
              line-height: 15px;
              font-size: $font-size-xs;
              color: $main-1;
            }
            .currentrank-span-text {
              line-height: 15px;
              font-size: $font-size-small;
              color: $main-1;
            }
            .rankvalue-span-text {
              line-height: 17px;
              font-size: $font-size-normal;
              color: $main-1;
              font-weight: 600;
            }
          }
          .opacity {
            opacity: 0.3;
            align-items: flex-end;
          }
        }
      }
    }
  }
  @media (max-width: 950px) {
    .profile-view-container-1 {
      justify-content: center;
    }
  }
  .bio-section-root-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0px;
    border-bottom: 1px solid #eef0fa33;
    .left-container {
      width: 65%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      .bio-section-button-container {
        display: flex;
        .bio-save-button {
          background: $main-2;
          // border: 1px solid $main-2;
          box-sizing: border-box;
          border-radius: 6px;
          color: #000000;
          font-size: $font-size-small;
          line-height: 15px;
          text-align: center;
          text-transform: uppercase;
          width: 100%;
          height: 44px;
          margin-top: 25px;
          outline: none;
          cursor: pointer;
        }
        .bio-cancel-button {
          background: $main-9;
          border: 1px solid $main-9;
          box-sizing: border-box;
          border-radius: 6px;
          color: $main-1;
          font-size: $font-size-small;
          line-height: 15px;
          text-align: center;
          text-transform: uppercase;
          width: 100%;
          height: 44px;
          margin-top: 25px;
          outline: none;
          cursor: pointer;
        }
      }
      .edit-bio-input-container {
        background: $main-3;
        border-radius: 8px;
        color: $main-1;
        border: none;
        padding: 12px;
        outline: none;
        height: 90px;
        width: 90%;
        resize: none;
      }
      .bio-text {
        color: $main-2;
        line-height: 17px;
        font-size: $font-size-normal;
      }
      .bio-data {
        min-height: 60px;
        max-width: 560px;
        word-break: break-word;
        text-overflow: ellipsis;
        font-size: $font-size-normal;
        line-height: 22px;
      }
      .editbio-button {
        color: $main-2;
        line-height: 17px;
        font-size: $font-size-normal;
        font-weight: $font-weight-1;
        cursor: pointer;
      }
    }
    .right-container {
      display: flex;
      flex-direction: column;
      width: 35%;
      align-items: flex-start;
      margin-left: 25px;
      .top-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 34px;

        .experience-text {
          margin-left: 24px;
          font-size: $font-size-l;
          line-height: 22px;
          font-weight: $font-weight-1;
        }
        .breaker-logo {
          height: 18px;
          width: 24px;
        }
      }
      .bottom-container {
        display: flex;
        justify-content: center;
        align-items: center;
        .star-logo {
          height: 24px;
          width: 24px;
        }
        .rater-container {
          margin-left: 24px;
          display: flex;
          flex-direction: column;
          .rater-text {
            opacity: 0.8;
            line-height: 15px;
            font-size: $font-size-small;
            font-weight: $font-weight-1;
            margin-bottom: 6px;
          }
          .date-text {
            opacity: 0.5;
            line-height: 15px;
            font-size: $font-size-small;
          }
        }
      }
    }
  }
  @media (max-width: 950px) {
    .bio-section-root-container {
      flex-direction: column;
      .left-container {
        width: 80%;
        .bio-section-button-container {
          display: block;
        }
      }
      .right-container {
        width: 80%;
        margin-left: unset;
        margin-top: 35px;
      }
    }
  }
  .role-section-root-container {
    display: flex;
    justify-content: center;
    padding: 30px 0px;
    flex-direction: column;
    max-width: fit-content;
    .role-accordion-container {
    }
    .span-role-text {
      font-size: $font-size-normal;
      line-height: 28px;
    }
    .roles-list-container {
      display: flex;
      gap: 16px;
      margin-top: 34px;
      .role-button-container {
        border: 1px solid $main-2;
        box-sizing: border-box;
        border-radius: 8px;
        cursor: pointer;
        padding: 8px 20px;
        .role-container {
          .role-name-text {
            font-size: $font-size-normal;
            line-height: 200%;
            text-align: center;
            color: $main-1;
          }
        }
      }
      .roles-selected {
        background: #19181c;
        border: 0;
      }
    }
  }
}
.roles-drawer {
  background: #0c0c10;
  min-width: 300px;
  max-width: 320px;
  display: flex;
  flex-direction: column;
  height: 100%;
  .edit-profile-root-container {
    padding: 0px 20px;
    overflow-y: auto;
    max-height: calc(100% - 100px);
    .edit-profile-type-text {
      display: inline-block;
      color: $main-1;
      font-size: $font-size-22;
      line-height: 27px;
      font-weight: $font-weight-1;
      margin: 24px 0px;
    }
    .submit-button {
      background: $main-2;
      // border: 1px solid $main-2;
      box-sizing: border-box;
      border-radius: 6px;
      color: #000000;
      font-size: $font-size-small;
      line-height: 15px;
      text-align: center;
      text-transform: uppercase;
      width: 100%;
      height: 44px;
      margin-top: 25px;
      outline: none;
      font-weight: 700;
    }
    .phone-verify-txt-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 25px;
      .content-verify {
        margin-left: 16px;
        display: inline-block;
        font-size: $font-size-small;
        line-height: 15px;
        color: $main-1;
        opacity: 0.5;
      }
    }
    .textfield-container {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 17px 0px;
      .phone-container {
        display: flex;
        justify-content: space-between;
        .textfield-error-text {
          line-height: 15px;
          font-size: $font-size-small;
          color: $main-8;
        }
      }
      .textfield-name-text {
        line-height: 15px;
        font-size: $font-size-small;
        color: $main-2;
      }
      .input-container {
        background: $main-3;
        border-radius: 8px;
        color: $main-1;
        margin-top: 10px;
        border: none;
        padding-left: 15px;
        outline: none;
        height: 44px;
      }
      .verification-for-edit-action {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        .landing-acpt-cook-btn,
        .landing-i-acpt-cook-btn {
          width: 48%;
          min-width: unset;
        }
      }
      .verification-code-container {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        .verification-code-input {
          width: 60px;
          margin: 15px 10px 0px 0px;
          background: $main-3;
          border-radius: 10px;
        }
        .MuiOutlinedInput-input {
          color: $main-1;
          text-align: center;
        }
        .MuiOutlinedInput-notchedOutline {
          border: none;
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        input[type="number"] {
          -moz-appearance: textfield;
        }
        .MuiOutlinedInput-input {
          padding: 12px;
        }
      }
      .landing-acpt-cook-btn {
        width: 150px;
        height: 48px;
        bottom: 36px;
        background: $main-7;
        border-radius: 8px;
        border: none;
        color: $main-1;
        outline: none;
        margin-top: 10px;
        text-transform: uppercase;
        font-weight: 500;
        cursor: pointer;
      }
      .landing-i-acpt-cook-btn {
        width: 150px;
        height: 48px;
        bottom: 36px;
        background: $main-2;
        border-radius: 8px;
        border: none;
        color: $main-4;
        margin-left: 15px;
        margin-top: 10px;
        outline: none;
        text-transform: uppercase;
        font-weight: 500;
        cursor: pointer;
      }
      .phone-input-container {
        display: flex;
        align-items: center;
        // flex-wrap: wrap;
        .phone-number-input {
          width: 130px;
        }
        .MuiFormControl-root {
          min-width: 75px;
          height: 70px;
        }
        .MuiOutlinedInput-root {
          background: $main-3;
          border-radius: 8px !important;
          color: $main-1 !important;
          .MuiSelect-outlined {
            padding: 15px;
          }
          .dropdown-icon {
            width: 15px;
            height: 15px;
            margin-right: 10px;
          }
        }
      }
    }
  }
  .drawer-header {
    padding: 16px 0;
    border-bottom: 1px solid #222223;
    padding-left: 20px;
    .close-icon {
      height: 17px;
      width: 17px;
      cursor: pointer;
    }
  }
  .role-list-root-container {
    padding: 25px;
    .drawer-title {
      font-size: $font-size-22;
      line-height: 27px;
      font-weight: $font-weight-1;
      color: $main-1;
    }

    .role-list-container {
      max-height: calc(100vh - 240px);
      overflow: auto;
      margin-top: 20px;
      .role-item-container {
        border-bottom: 1px solid #222223;
        display: flex;
        align-items: center;
        gap: 26px;
        padding: 20px 0px;
        .role-text {
          color: $main-1;
          font-size: $font-size-small;
          line-height: 15px;
        }
      }
    }
    .done-button {
      background: $main-2;
      border: 1px solid $main-2;
      box-sizing: border-box;
      border-radius: 8px;
      color: $main-1;
      font-size: $font-size-15;
      line-height: 24px;
      text-align: center;
      text-transform: uppercase;
      width: 100%;
      height: 60px;
      margin-top: 25px;
      outline: none;
    }
    .done-button-disable {
      background: $main-2;
      border: 1px solid $main-2;
      box-sizing: border-box;
      border-radius: 8px;
      color: $main-1;
      font-size: $font-size-15;
      line-height: 24px;
      text-align: center;
      text-transform: uppercase;
      width: 100%;
      height: 60px;
      margin-top: 25px;
      opacity: 0.5;
    }
  }
  .room-list-container {
    // display: flex;
    // justify-content: center;
    margin: 20px auto;
    width: calc(100% - 20px);
    box-sizing: border-box;

    .notification {
      background-color:$main-4;

      .ant-notification-notice-content {
        .ant-notification-notice-message {
          color: $main-1;
        }
        color: $main-1;
      }
      .ant-notification-notice-close {
        color: rgba(255, 255, 255, 0.45);
      }
    }
  }
  .profile-list-container {
    height: inherit;
    padding: 0px 20px;
    .room-card-root-container {
      height: 250px;
      margin-bottom: 16px;
      width: 100%;
      display: flex;
      background-position: center center !important;
      background-size: cover !important;
      border-radius: 6px;
      position: relative;

      .bottomFade {
        position: absolute;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        width: 100%;
        bottom: 0;
        left: 0;
        right: 0;
        height: 30%;
        background: linear-gradient(360deg, $main-4 0%, rgba(24, 24, 26, 0) 100%);
      }

      .room-card-view-container {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        margin: 20px;
        flex: 1;

        .room-info-container {
          display: flex;
          flex-direction: column;
          gap: 6px;
          .room-title-text {
            color: $main-1;
            line-height: 17px;
            font-size: $font-size-normal;
            font-weight: $font-weight-1;
          }
          .room-by-text {
            opacity: 0.8;
            color: $main-1;
            line-height: 15px;
            font-size: $font-size-small;
          }
          .room-time-text {
            opacity: 0.8;
            color: $main-2;
            line-height: 15px;
            font-size: $font-size-small;
          }
        }
        .room-card-header-container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .room-card-header-item {
            height: 34px;
            width: 34px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background: linear-gradient(180deg, #18181a 0%, #1d1d1d 100%);
            cursor: pointer;

            svg {
              width: 14px;
              height: 14px;
            }

            .userpic {
              width: 34px;
              height: 34px;
              border-radius: 50%;
            }
          }
          .room-card-header-item-active {
            background: linear-gradient(180deg, #C8A86B 0%, #725E3A 100%), linear-gradient(180deg, #18181A 0%, #1D1D1D 100%);
            svg {
              path {
                fill: $main-4;
              }
            }
          }
        }
      }
    }
    .people-card-container {
      padding: 20px 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .follow-button {
        height: 34px;
        background-color: $main-11;
        border-radius: 6px;
        justify-content: center;
        align-items: center;
        display: flex;
        cursor: pointer;
        .follow-text {
          color: $main-1;
          line-height: 14.63px;
          font-size: $font-size-small;
          padding: 0px 15px;
        }
      }
      .left-container {
        display: flex;
        column-gap: 10px;
        .avatar-style {
          height: 34px;
          width: 34px;
          border-radius: 50%;
        }
        .detail-container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .display-name-text {
            opacity: 0.8;
            font-size: $font-size-small;
            color: $main-1;
            line-height: 14.63px;
            font-weight: $font-weight-1;
            word-break: break-all;
            padding-right: 10px;
          }
          .role-text {
            opacity: 0.5;
            font-size: $font-size-small;
            color: $main-1;
            line-height: 14.63px;
          }
        }
      }
    }
  }
  .sliderContainer {
    display: flex;
    justify-content: center;
    background: inherit;
  }
  .people-list-header-text {
    color: $main-1;
    font-size: $font-size-22;
    font-weight: $font-weight-1;
    line-height: 27px;
    padding: 24px 20px 12px;
  }
  .search-bar-root-container {
    height: 44px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px 0px;
    .search-input-container {
      width: 284px;
      height: 44px;
      background: $main-11;
      border-radius: 6px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 19px 0px 16px;
      .search-input {
        width: 100%;
        margin-left: 16px;
        background: $main-11;
        border: none;
        outline: none;
        color: $main-1;
        opacity: 0.6;
        font-size: $font-size-normal;
        line-height: 17px;
      }
      .clear-button {
        cursor: pointer;
      }
    }
  }
}
