@import "../../scss/variables.scss";
@import "../../scss/mixins";
.hide-on-mobile {
  @include mq("phone-wide", max) {
    display: none;
  }
}
.internship-landing {
  hr {
    border: 0.5px solid #725e3a;
    width: 100%;
    margin-top: 150px;
    margin-bottom: 0;
    @include mq("phone-wide", max) {
      width: 303px;
    }
  }

  .nav-wrap {
    z-index: 999999 !important;
  }
  //   z-index: -1;
  position: relative;
  // height: 180vh ;
  background: #18181a;

  height: 100%;

  .container {
    // height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    .join {
      width: 100%;
      height: 607px;
      background: rgba(0, 0, 0, 0.6);
      display: flex;
      justify-content: center;
      align-items: center;
      @include mq("phone-wide", max) {
        margin-top: 2em;
        height: 509px;
      }

      .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .desc {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 28px;
          line-height: 148.06%;
          text-align: center;
          color: #ffffff;
          width: 1102px;
          @include mq("phone-wide", max) {
            font-size: 16px;
            width: 303px;
          }
          // height: 164px;
        }
        .join-text {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 28px;
          line-height: 148.06%;
          text-align: center;
          color: #ffffff;
          margin-top: 1em;
          @include mq("phone-wide", max) {
            font-size: 16px;
            width: 303px;
          }
        }
        button {
          margin-top: 3em;
          background: #c8a86b;
          border-radius: 6px;
          width: 284px;
          height: 55px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 148.06%;
          text-align: center;
          text-transform: uppercase;
          color: #000000;
          border: none;
          outline: none;
          cursor: pointer;
        }
        .write-us-email {
          margin-top: 3em;
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;
          a {
            color: #c8a86b;
          }
        }
      }
    }
    // height: 100%;
    .program-benifits {
      margin-top: 150px;
      margin-bottom: 8em;
      // width: 100%;
      // width: 918px;
      display: flex;
      justify-content: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @include mq("phone-wide", max) {
        margin-top: 8em;
        margin-bottom: 0em;
      }
      h1 {
        text-align: center;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 900;
        font-size: 36px;
        line-height: 44px;
        color: #ffffff;
        margin-bottom: 80px;
        @include mq("phone-wide", max) {
          font-size: 25px;
          text-align: center;
          width: 303px;
        }
      }
      .programs {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .program-wrap {
          background: #18181a;
          border: 1px solid #5634c2;
          box-sizing: border-box;
          box-shadow: 0px 0px 20px rgba(86, 52, 194, 0.4);
          border-radius: 6px;
          width: 369px;
          height: 369px;
          display: flex;
          justify-content: center;
          align-items: center;
          .program {
            border-radius: 12px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            // padding: 4.2em 2em;
            //     @include mq("phone-wide", max) {
            //         padding: 2em 2em;
            //    }
            h2 {
              text-align: center;
              margin: 0;
              font-family: Montserrat;
              font-style: normal;
              font-weight: 900;
              font-size: 100px;
              line-height: 122px;
              background: linear-gradient(
                279.64deg,
                rgba(200, 168, 107, 0.7) -2.36%,
                rgba(86, 52, 194, 0.7) 27.27%,
                rgba(86, 52, 194, 0.7) 55.12%,
                rgba(200, 168, 107, 0.7) 92.53%
              );
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;

              &::-moz-selection {
                /* Code for Firefox */
                -webkit-background-clip: none;
                -webkit-text-fill-color: #fcfcfc;
              }

              &::selection {
                -webkit-background-clip: none;
                -webkit-text-fill-color: #fcfcfc;
              }
              @include mq("phone-wide", max) {
                font-size: 80px;
              }
            }
            h1 {
              // text-align: left;
              font-family: Montserrat;
              font-style: normal;
              font-weight: 900;
              font-size: 20px;
              line-height: 24px;
              text-transform: uppercase;
              color: #ffffff;
              width: 275px;
              margin: 17px;
              text-align: center;

              @include mq("phone-wide", max) {
                font-size: 14px;
                width: 195px;
              }
            }
            p {
              font-family: Montserrat;
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 24px;
              color: #ffffff;
              width: 275px;
              text-align: center;

              @include mq("phone-wide", max) {
                font-size: 14px;
                width: 195px;
              }
            }
          }
        }
        .first-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          @include mq("phone-wide", max) {
            flex-direction: column;
            .program-wrap {
              width: 280px;
              height: 280px;
              margin: 1em 0;
            }
          }
        }
        .second-container {
          display: flex;
          margin-top: 60px;
          @include mq("phone-wide", max) {
            flex-direction: column;
            margin-top: 0em;
          }
          .program-wrap {
            // width: 100%;
            width: 369px;
            height: 369px;
            // width: 444px !important;
            @include mq("phone-wide", max) {
              width: 280px;
              height: 280px;
              // margin: 1em 0;
              margin-bottom: 1em;
              margin-left: 0 !important;
              margin-right: 0 !important;
            }
          }
        }
      }
    }
    .certification {
      margin-top: 80px;
      width: 100%;
      display: flex !important;
      justify-content: center;
      align-items: center;
      @include mq("phone-wide", max) {
        margin-top: 1em;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      .certificate {
        width: 800px;
        height: 565px;
        margin-left: 12em;
        @include mq("phone-wide", max) {
          margin-left: 0;
          width: 276px;
          height: 198px;
          margin-top: 4em;
        }
      }
      .certificate2 {
        @include mq("phone-wide", max) {
          margin-left: 0;
          width: 276px;
          height: 198px;
          margin: 2em 0;
          // margin-top: 4em;
        }
      }
      .process-container {
        margin-top: 1em;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .purpleline {
          margin: 3em 0;
        }
        .process {
          width: 344px;

          display: flex;
          justify-content: space-around;
          align-items: center;
          @include mq("phone-wide", max) {
            width: 260px;
          }
          .icon {
            width: 68px;
            height: 68px;
            @include mq("phone-wide", max) {
              width: 48px !important;
              height: 48px !important;
            }
          }
          .about-container {
            text-align: left;
            @include mq("phone-wide", max) {
              margin-left: 1em;
            }
            h1 {
              font-family: Montserrat;
              font-style: normal;
              font-weight: 600;
              font-size: 20px;
              line-height: 24px;
              text-transform: uppercase;
              color: #c8a86b;
              @include mq("phone-wide", max) {
                font-size: 18px;
              }
            }
            p {
              width: 220px;
              font-family: Montserrat;
              font-style: normal;
              font-weight: normal;
              font-size: 15px;
              line-height: 140%;
              color: #ffffff;
              @include mq("phone-wide", max) {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
    .launch-text {
      margin-top: 150px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 900;
      font-size: 36px;
      line-height: 58px;
      text-align: center;
      // width: 385px;
      // height: 116px;

      color: #ffffff;
      @include mq("phone-wide", max) {
        // display: none;
        font-size: 24px;
        width: 280px;
        line-height: 38px;
      }
    }
    .crowd-container {
      margin-top: 5em;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @include mq("phone-wide", max) {
        margin-top: 26em;
      }
      width: 100%;
      background: #18181a;
      p {
        text-align: center;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 148.06%;
        color: #ffffff;
        opacity: 0.6;
      }
      .info-text {
        color: #8b8b8c;
        text-align: center;
        font-weight: 500;
        @include mq("phone-wide", max) {
          width: 250px;
          span {
            font-size: 14px;
          }
        }
        img {
          height: 8px;
          margin-left: 12px;
        }
      }
      .crowd {
        text-align: center;
        margin-bottom: 16px;
        img {
          height: 40px;
        }
      }
    }
    .top-container {
      width: 1160px;
      z-index: 1;
      width: 80%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      //   height: 1080px;
      @include mq("phone-wide", max) {
        height: 1080px;
      }

      .main-content {
        padding: 3em 8em;
        padding-top: 2em;
        // padding-left: 15em;
        @include mq("phone-wide", max) {
          padding: 0;
          padding-top: 14em;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }
        z-index: -1;
        width: 100%;
        height: 100%;
        background-color: #18181a;
        position: relative;

        .steps {
          margin-top: 3em;
          // height: 380px;
          padding: 54px 0;
          width: 1160px;
          @include mq("desktop", min) {
            width: 100%;
          }
          //   @include mq("desktop-small", max) {
          //       width: 120%;
          // }

          // padding: 3em 4em;
          background: rgba(0, 0, 0, 0.6);
          backdrop-filter: blur(20px);
          border-radius: 6px;
          display: flex;
          justify-content: center;
          @include mq("phone-wide", max) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 320px;
            // height: 676px;
            height: 100%;
          }
          // padding: 0 4em;
          // padding-left: 4em;
          align-items: center;
          @include mq("phone-wide", max) {
            padding: 3em 0;
          }
          .step {
            // margin: 0 2em;
            // margin-left: 4em;

            // margin: 0 3em;
            @include mq("phone-wide", max) {
              margin: 4px 1em !important;
            }
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            text-align: left;
            position: relative;
            @include mq("phone-wide", max) {
              flex-direction: row;
            }
            .arrow {
              position: absolute;
              top: 16px;
              right: 106px;
              width: 120px;
              .line {
                margin-top: 5px;
                width: 267px;
                background: #c8a86b;
                height: 1px;
                float: left;
              }
              .line2 {
                margin-top: 5px;
                width: 267px;
                background: #8563f5;
                height: 1px;
                float: left;
              }
            }
            .point {
              width: 0;
              height: 0;
              border-top: 5px solid transparent;
              border-bottom: 5px solid transparent;
              border-left: 5px solid #c8a86b;
              float: right;
              position: absolute;
              right: -152px;
            }
            .point2 {
              width: 0;
              height: 0;
              border-top: 5px solid transparent;
              border-bottom: 5px solid transparent;
              border-left: 5px solid #8563f5;
              float: right;
              position: absolute;
              right: -152px;
            }
          }
          .purple {
            color: #8563f5 !important;
            @include mq("phone-wide", max) {
              color: #644fa8 !important;
            }
          }
          .index {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            line-height: 160.9%;
            color: #c8a86b;
            @include mq("phone-wide", max) {
              font-size: 22px;
            }
          }
          div {
            @include mq("phone-wide", max) {
              margin-left: 3em;
            }
            .desc {
              font-family: Montserrat;
              font-style: normal;
              font-weight: 500;
              font-size: 20px;
              line-height: 160.9%;
              color: #c8a86b;
            }
            ul {
              margin: 0;
              padding: 0;
              li {
                margin: 1em 0;
                font-family: Montserrat;
                font-style: normal;
                font-weight: normal;
                font-size: 15px;
                line-height: 140%;
                color: #ffffff;
                @include mq("phone-wide", max) {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
      .first-container {
        @include mq("phone-wide", max) {
          margin-top: 10em;
        }
        .intern-lottie {
          position: absolute;
          top: -147px;
          z-index: -1;
          height: 92%;
          width: 49%;
          right: 111px;
          background: transparent;
          @include mq("phone-wide", max) {
            height: 50%;
            width: 100%;
            right: 10px;
            top: 11px;
          }
        }
        .apply-now {
          display: flex;
          justify-content: flex-start;
          flex-direction: column;
          align-items: flex-start;
          @include mq("phone-wide", max) {
            justify-content: center;
            //   flex-direction: column;
            align-items: center;
          }
          h1 {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 148.06%;
            text-transform: uppercase;
            color: #c8a86b;
          }
          .desc-container {
            margin-top: 48px;
            @include mq("phone-wide", max) {
              margin-top: 25em;
            }
            .desc {
              font-family: Montserrat;
              font-style: normal;
              font-weight: 600;
              font-size: 42px;
              line-height: 140%;
              color: #ffffff;
              text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
              width: 649px;
              height: 177px;
              @include mq("phone-wide", max) {
                font-size: 28px;
                width: 287px;
                text-align: center;
              }
            }
            button {
              margin-top: 3em;
              background: #c8a86b;
              border-radius: 6px;
              width: 287px;
              height: 55px;
              font-family: Montserrat;
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 148.06%;
              text-align: center;
              text-transform: uppercase;
              color: #000000;
              border: none;
              outline: none;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
