@import "../../scss/variables.scss";

.track-details-status-container {
  height: 70px;
  width: 100%;
  border-bottom: 1px solid $main-12;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 1s linear;

  .back-to-track-action {
    padding-left: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
      margin-left: 16px;
      font-size: $font-size-normal;
      @media (max-width: 670px) {
        display: none;
      }
    }
  }

  .track-status-title {
    font-size: $font-size-normal;
    font-weight: $font-weight-4;
  }

  .track-setting {
    padding-right: 40px;
    cursor: pointer;
  }
}

.track-details-review-section {
  display: flex;
  margin: 0 auto;

  @media (max-width: 670px) {
    display: block;
    margin: 0 40px;
    .track-card-root-container {
      margin-right: 0;
      width: 100%;
    }
  }
}

.track-details-cards {
  display: flex;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: center;
  border-bottom: 1px solid $main-11;
  padding-bottom: 34px;

  @media (max-width: 670px) {
    margin: 0 40px;
    display: block;
  }
}

.influencer-waiting-info {
  padding-top: 34px;
  display: flex;
  margin: 0 auto;

  // padding-bottom: 34px;

  @media (max-width: 987px) {
    .track-info-card {
      display: none;
    }
    margin: 0 40px;
    display: block;
  }

  @media (max-width: 670px) {
    .track-info-card {
      display: none;
    }
    margin: 0 40px;
    display: block;
  }
}

.influencer-review-list {
  padding-top: 4px;
  min-width: 850px;
  max-width: 870px;
  margin: 0 auto;
  @media (max-width: 987px) {
    width: auto;
    min-width: unset;
    margin: 0 40px;
  }
  .lists {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    @media (max-width: 987px) {
      justify-content: center;
    }
    @media (max-width: 670px) {
      display: block;
    }
  }
}

.track-detail-comments {
  border-top: 1px solid $main-11;
  padding: 34px 0;
  min-width: 850px;
  max-width: 870px;
  margin: 0 auto;
  margin-top: 34px;

  @media (max-width: 987px) {
    width: auto;
    min-width: unset;
    margin: 0 40px;
    margin-top: 34px;
  }

  &-header {
    display: flex;
    align-items: center;

    img {
      margin-right: 12px;
    }
  }

  &-count {
    font-size: 22px;
    font-weight: 500;
  }

  &-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    @media (max-width: 987px) {
      justify-content: center;
    }
    @media (max-width: 670px) {
      display: block;
    }
  }

  &-tagline {
    font-size: $font-size-normal;
    font-weight: $font-weight-4;
    color: rgba($main-1, 0.5);
    padding: 34px 0;
  }
}

.track-details-pricing {
  background: $main-10;
  padding-bottom: 40px;

  &-header {
    text-align: center;
    font-size: 22px;
    font-weight: 500;
    padding: 44px 40px;
  }

  &-card-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    @media (max-width: 670px) {
      display: block;
    }
  }

  &-card {
    background: $main-11;
    border-radius: 6px;
    width: 415px;
    margin-bottom: 20px;
    @media (max-width: 670px) {
      width: 100%;
    }

    &.alt {
      background: transparent;
      border: 1px solid $main-11;
    }

    &.mr {
      margin-right: 20px;
    }

    &-header {
      padding: 34px 0 24px 0;
      margin: 0 34px;
      text-align: center;
      border-bottom: 1px solid rgba(238, 240, 250, 0.1);

      .type {
        color: $main-2;
        font-size: $font-size-normal;
        font-weight: $font-weight-1;
        padding-bottom: 12px;
        text-transform: uppercase;
      }

      .amount-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .amount {
        font-size: 24px;
        font-weight: $font-weight-4;
      }
    }

    &-content {
      text-align: center;
      .title {
        padding: 24px 0;
        font-weight: $font-weight-1;
        font-size: $font-size-normal;
        text-transform: uppercase;
        color: #42ae55;
        &.primary {
          color: $main-2;
        }
      }
      .description {
        font-size: $font-size-normal;
        font-weight: 300;
        padding: 0 34px;
        line-height: 30px;
        max-width: 336px;
        margin: 0 auto;
        padding-bottom: 24px;
      }

      .text {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: $font-size-normal;
        font-weight: $font-weight-1;
        color: $main-1;
        padding-bottom: 18px;
        text-transform: uppercase;

        &.pb24 {
          padding-bottom: 24px;
        }

        img {
          margin-right: 12px;
        }

        &.primary {
          color: $main-2;
        }

        &.green {
          color: #42ae55;
        }
      }

      .action {
        padding: 0 24px;
        padding-top: 10px;
        padding-bottom: 34px;
      }
    }
  }
}

.pricing-action-button {
  background: transparent;
  text-transform: uppercase;
  border: 1px solid $main-2;
  font-size: $font-size-small;
  font-weight: $font-weight-1;
  padding: 15px 0;
  width: 100%;
  color: $main-1;
  border-radius: 4px;
}

.track-details-reupload-track {
  flex: 1;
  height: 270px;
  width: 560px;
  margin: 20px 0;
  background: $main-10;
  border-radius: 6px;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 670px) {
    text-align: center;
    display: block;
    width: 100%;
    height: auto;
    padding-bottom: 24px;
  }
  .upload-button {
    display: flex;
    justify-content: center;
  }
  .upload-track-button {
    margin-top: 24px;
    font-size: 12px;
    font-weight: 600;
  }

  .title {
    text-align: center;
    color: $main-8;
    font-size: 12px;
    font-weight: 600;
  }

  .subtitle-error {
    text-align: center;
    color: $main-8;
    font-size: 12px;
    font-weight: 400;
    margin-top: 24px;
  }

  .selected-file {
    padding-top: 34px;
    display: grid;
    grid-template-columns: 84px 1fr;
    align-items: center;

    .delete-selected-file {
      cursor: pointer;
      width: 84px !important;
      height: 84px;
      background: rgba($main-3, 0.5);
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .selected-file-title {
      font-size: $font-size-22;
      font-weight: $font-weight-4;
      margin-left: 20px;
      word-break: break-all;
    }
    .selected-file-subtitle {
      font-size: 12px;
      margin-left: 20px;
      color: $main-8;
      line-height: 200%;
      padding-top: 4px;
      word-break: break-all;
    }
  }

  .file-dropper {
    padding-top: 24px;

    &.error {
      .file-dropper-wrapper {
        border-color: $main-8;
      }
    }

    &-title {
      font-size: $font-size-medium;
      font-weight: $font-weight-4;
      line-height: 32px;
      span {
        color: $main-2;
        font-weight: $font-weight-1;
      }
    }

    &-subtitle {
      font-size: $font-size-small;
      font-weight: 300;
      color: rgba($main-1, 0.5);
      line-height: 24px;
      span {
        color: $main-1;
        font-weight: $font-weight-1;
      }
    }
  }
  .file-dropper-wrapper {
    border: 1px dashed;
    border-color: rgba($main-2, 0.5);
    height: 84px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
