@import "../../scss/variables.scss";
@import "../../scss/mixins";

.home{
    height: 100vh;
    .signup-create-account{
        border-top: none;
        height: 27%;
    }
    .internship-main__container{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .signup-form__container{
            margin-top: 2em;
        }

        .title{
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            display: flex;
            align-items: center;
            text-transform: uppercase;
            @include mq("phone-wide", max) {
                font-size: 12px;
                line-height: 15px;
            }
        }

        .account-login__container{
            @include mq("phone-wide", max) {
                margin-top: 216px;
            }
            .account-login__title{
                font-family: Montserrat;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 22px;
                text-align: center;
                color: #FFFFFF;
            }
            .login-now__btn{
                font-family: Montserrat;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 22px;
                text-align: center;
                color: #C8A86B;
            }
        }
    }

}
