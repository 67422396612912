@import "../../scss/variables.scss";
@import "../../scss/mixins";

.podcast{
    background-color: #18181A !important;
    // height: 115vh;
    height: 100%;
    @include mq("phone-wide", max) {
        height: auto;
    }
    @include mq("desktop-wide", min) {
        height: 100vh;
    }
    .container{
        background-color: #18181A;
        // margin-top: 10em;
        @include mq("desktop-wide", max) {
            // margin-top: 10em;
        }
        // @include mq("desktop", max) {
        //     margin-top:10em;
        // }
        @include mq("phone-wide", max) {
            margin-top: 0em ;
        }

        padding-bottom: 10em;
        height: 100%;
        background-color: #18181A;
        color: #ffffff !important;
        display: flex;
        justify-content: center;
        align-items: center;
        @include mq("phone-wide", max) {
            flex-direction: column;
        }

        .left{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .welcome-container{
                margin-bottom: 30px;
                @include mq("phone-wide", max) {
                    margin-bottom: 40px;
                }
                margin-top: 40px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .welcome-title{
                    font-family: Montserrat;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 20px;
                    text-align: center;
                    text-transform: uppercase;
                    color: #FFFFFF;
                }
                .welcome-border{
                    background: linear-gradient(279.64deg, #C8A86B -2.36%, #5634C2 27.27%, #5634C2 55.12%, #C8A86B 92.53%);
                    height: 2px;
                    border: none;
                    width: 139px;
                }
            }


            .main-title{
                width: 772px;
                font-family: Montserrat;
                font-style: normal;
                font-weight: 900;
                font-size: 60px;
                line-height: 73px;
                text-align: center;
                color: #C8A86B;
                margin-bottom: 50px;
                @include mq("phone-wide", max) {
                    margin-bottom: 0px;
                    width: 306px;
                    font-size: 25px;
                    line-height: 30px;
                    text-align: center;
                }
            }
            .cheat-code__text{
                display: none;
                @include mq("phone-wide", max) {
                    display: block;
                    width: 250px;
                    height: 250px;
                    margin: 40px 0;
                }
            }

            .listen-now__container{
                margin-bottom: 1em;
                width: 588px;
                display: flex;
                justify-content: center;
                align-items: center;
                @include mq("phone-wide", max) {
                    width: auto;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
                input{
                    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                        color: #ffffff;
                        opacity: 1; /* Firefox */
                    }

                    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                        color: #ffffff;
                    }

                    &::-ms-input-placeholder { /* Microsoft Edge */
                        color: #ffffff;
                    }
                    // height: 44px;
                    background: #18181a;
                    flex: 1;
                    margin-right: 20px;
                    font-size: 16px;
                    border: 1px solid #c8a86b;
                    padding: 15px 20px;
                    border-radius: 6px;
                    color: #fff;
                    outline: none;
                    @include mq("phone-wide", max) {
                        margin-bottom: 10px;
                        width: 360px;
                        // height: 44px;
                        margin-right: 0;
                    }
                }
                .listen-now__btn{
                    border: none;
                    outline: none;
                    background: #c8a86b;
                    color: #000;
                    padding: 18px 0;
                    width: 220px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 14px;
                    font-weight: 500;
                    text-transform: uppercase;
                    border-radius: 4px;
                    cursor: pointer;
                    @include mq("phone-wide", max) {
                        width: 360px;
                        padding: 15px 0;
                    }
                }
            }

            .about-text{
                margin-top: 20px;
                width: 607px;
                font-family: Montserrat;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 29px;
                color: #FFFFFF;
                @include mq("phone-wide", max) {
                    width: 360px;
                }
            }
        }

        .right{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .mobile-image{
                width: 334px;
                height: 652px;
                @include mq("phone-wide", max) {
                    display: none;
                }
            }
            .podcast-on{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .stream-text{
                    margin: 30px 0;
                    font-family: Montserrat;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 151.4%;
                    color: #FFFFFF;
                }
                .listen-on__container{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @include mq("phone-wide", max) {
                        flex-direction: column;
                    }
                    .google-podcast{
                        margin-left: 20px;
                        @include mq("phone-wide", max) {
                            margin-top: 15px;
                            margin-left: 0px;
                            margin-bottom: 15px;
                        }
                    }
                    .listen-on-podcast{
                        // cursor: pointer;
                        padding: 10px 20px;
                        border: 1px solid #C8A86B;
                        border-radius: 9px;
                        .listen-on-podcast__container{
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            width: 188px;
                            @include mq("desktop", max) {
                                width: 165px;
                            }
                            .listen-on-podcast-image{
                                width: 36px;
                                height: 36px;
                            }
                            .listen-on-podcast__details{
                                // margin-left: 10px;
                                display: flex;
                                flex-direction: column;
                                font-family: Montserrat;
                                font-style: normal;
                                font-weight: normal;
                                text-align: left;
                                .listen-on__text{
                                    font-size: 10px;
                                    line-height: 151.4%;
                                    color: #FFFFFF;
                                }
                                .podcast-brand{
                                    font-size: 14px;
                                    line-height: 151.4%;
                                    color: #FFFFFF;
                                }
                            }

                        }
                    }
                }
            }
        }
    }
}
