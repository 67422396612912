.file-drop {
  /* relatively position the container bc the contents are absolute */
  position: relative;
  width: 100%;
  height: 100%;

  .file-drop-target {
    /* basic styles */
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 8px;
    /* horizontally and vertically center all content */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    text-align: center;
  }

  .file-drop-target.file-drop-dragging-over-target {
    /* turn stuff orange when we are dragging over the target */
    color: #c8a86b;
    box-shadow: 0 0 13px 3px #c8a86b;
  }
}
