@import "../../scss/variables.scss";

.root-container {
  background: $main-4;
  height: 100vh;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0px 5vw 0px 5vw;
  max-width: 100vw;
}
.login-view-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.tag-line-text {
  font-size: $font-size-normal;
  line-height: $font-size-17;
  font-weight: $font-weight-1;
  color: $main-1;
  padding: 30px 0px 30px 0px;
}
.login-button-container {
  background: $main-2;
  border: 1px solid $main-2;
  box-sizing: border-box;
  border-radius: 8px;
  color: $main-3;
  font-weight: $font-weight-1;
  font-size: $font-size-normal;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  height: 50px;
  margin-top: 25px;
  outline: none;
  max-width: 350px;
}
.resend-button-container {
  @extend .login-button-container;
  background: $main-7;
  color: $main-1;
  border: 1px solid $main-7;
}
.done-button-container {
  @extend .login-button-container;
}
.register-txt-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  margin-top: 30px;
  font-size: $font-size-normal;
  cursor: default;
  color: $main-1;
  .register-txt {
    color: $main-2;
    line-height: 25px;
  }
}
.sms-message-span {
  font-size: $font-size-normal;
  line-height: $font-size-17;
  color: $main-1;
  opacity: 0.5;
  padding-left: 10px;
  max-width: calc(100% - 40px);
}
.sms-info-container {
  padding: 0px 10px 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 340px;
  margin-top: 20px;
}
.breaker-logo-style {
  height: 90px;
}
