@import "../../scss/variables.scss";
@import "../../scss/mixins";

.pricing-plan__header {
  padding: 40px 48px;
  //   padding-top: 5em;
  padding-bottom: 0;
  margin-bottom: 24px;

  @include mq("phone-wide", max) {
    padding: 2em 1.4em;
    padding-bottom: 0;
    margin-bottom: 1.6em;
    margin-top: 20px;
  }
  .pricing-plan__header-title {
    text-align: left;
    margin-bottom: 10px;

    span {
      font-size: 14px;
      @include mq("phone-wide", max) {
        font-size: 14px;
      }
      line-height: 22px;
    }
    h2 {
      margin-top: 16px;
      font-weight: 900;
      font-size: 24px;
      @include mq("phone-wide", max) {
        margin-top: 0px;
        font-size: 30px;
      }
      line-height: 41px;
      // color: #ffffff;
      color: #c8a86b;

    }
  }
  .pricing-plan__header-desc {
    margin-top: 8px;
    @include mq("phone-wide", max) {
      margin-top: -10px;
    }
    span {
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      @include mq("phone-wide", max) {
        font-size: 14px;
      }
      line-height: 22px;
      color: #ffffff;
    }
  }
}

.pricing-plan__price {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #c8a86b;
  border-bottom: 1px solid #c8a86b;
  padding: 13px;
  .pricing-plan__price-value__container {
    .pricing-plan__dollar-amount {
      sup {
        vertical-align: top;
        position: relative;
        top: 1.2em;
        right: 2px;
        font-size: 23px;
        @include mq("phone-wide", max) {
          font-size: 20px;
        }
      }
      font-family: Montserrat;
      font-weight: 600;
      font-size: 36px;
      @include mq("phone-wide", max) {
        font-size: 34px;
      }
      color: #ffffff;
      .small {
        font-size: 20px;
        @include mq("phone-wide", max) {
          font-size: 8px;
        }
      }
    }
  }
}

.pricing-plan__features {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include mq("phone-wide", max) {
    // margin-bottom: -1em;
    padding: 0 20px;
  }

  ul {
    padding: 0;
    @include mq("phone-wide", max) {
      width: 100%;
      margin-left: 39px;
    }

    li {
      margin: 12px 0;

      display: flex;
      list-style: none;
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      @include mq("phone-wide", max) {
        font-size: 14px;
      }
      line-height: 22px;
      display: flex;
      align-items: center;
      color: #ffffff;
    }
    li:before {
      margin-bottom: 3px;
      color: transparent;
      content: " ";
      margin-left: -3.3em;
      margin-right: 10px;
      padding: 12px;
      background: linear-gradient(180deg, #c8a86b 0%, #725e3a 100%);
      -webkit-mask-image: url("../../assets/img/pricing/done_black.svg");
      -webkit-mask-size: cover;
    }
  }
}

.pricing-plan__explore-features {
  margin-top: 6px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include mq("phone-wide", max) {
    margin-bottom: 1.5em;
  }
  .pricing-plan__explore-features__title {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    @include mq("phone-wide", max) {
      font-size: 14px;
    }
    line-height: 22px;
    text-transform: uppercase;
    color: #c8a86b;

    .faq-arrow {
      cursor: pointer;
      margin-left: 25px;
      img {
        width: 15px;
      }
      @include mq("phone-wide", max) {
        margin-right: 16px;
        img {
          width: 14px;
        }
      }

      .faq-arrow__down {
        transition: 0.3s all;
        transform: rotate(-180deg);
      }
      .faq-arrow__up {
        transition: 0.3s all;
        transform: rotate(-360deg);
      }
    }
  }
  .pricing-plan__explore-features__container {
    width: 65%;
    @include mq("phone-wide", max) {
      width: 84%;
    }
    .explore-features {
      margin-top: 20px;
      @include mq("phone-wide", max) {
        margin-top: 6px;
      }
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      .explore-feature {
        width: 100%;
        border-bottom: 1px solid #725e3a;
        margin: 10px 0;
        .explore-feature__desc {
          width: 100%;
          margin-bottom: 10px;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          @include mq("phone-wide", max) {
            font-size: 12px;
          }
          line-height: 22px;
          color: #ffffff;
        }
        .explore-feature__wrap {
          cursor: pointer;
          margin-bottom: 10px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          span {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            @include mq("phone-wide", max) {
              font-size: 14px;
            }
            line-height: 22px;
            color: #ffffff;
            img {
              width: 14px;
            }
          }
        }
      }
    }
  }
}

.pricing-plan__free {
  padding: 3em 0em;
  padding-top: 3.2em;
  margin-top: 20px;
  width: 1160px;
  background: #222223;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .pricing-plan__free-title__container {
    margin-top: 6px;
    margin-left: 27px;
    .pricing-plan__free-title {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 42px;
      line-height: 51px;
      color: #ffffff;
    }
  }
  .pricing-plan__free-try-btn__container {
    height: 105.16px;
    width: 278px;
    position: relative;
    padding: 10px 20px;
    margin: 55px 3px;
    font-size: 15px;

    &::before {
      content: "";
      position: absolute;
      top: 20px;
      left: -7px;
      right: 49px;
      bottom: 29px;
      border-radius: 8px;
      padding: 2px;
      background: linear-gradient(180deg, #c8a86b 0%, #725e3a 100%);
      -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      mask-composite: add, add;
      mask-composite: add, add;
      -webkit-mask-composite: destination-out;
      mask-composite: exclude;
    }
    div {
      width: 278px;
      margin-left: 68px;
      margin-top: 29px;
    }
  }
}

// Plan card

.pricing-purple__border {
  border: 1px solid #5634c2;
  box-shadow: 0px 0px 30px rgba(86, 52, 194, 0.3);
}

.pricing-plan__card {
  //   margin: 0 10px;
  margin-top: 12px;
  background: #222223;
  border-radius: 10px;
  width: 370px;
  padding-bottom: 20px;

  @include mq("phone-wide", max) {
    width: 275px;
  }
  // height: 874px;
  .pricing-plan__free-try-btn__container {
    height: 118.16px;
    width: 319px;
    position: relative;
    padding: 10px 20px;
    margin: 18px 3px;
    font-size: 15px;
    margin-left: 80px;

    @include mq("phone-wide", max) {
      font-size: 12px;
      height: 95.16px;
      width: 273px;
      position: relative;
      padding: 10px 20px;
      margin: -11px 3px;
      margin-left: 27px;
      margin-bottom: 17px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 20px;
      left: -7px;
      right: 49px;
      bottom: 29px;
      border-radius: 8px;
      padding: 2px;
      background: linear-gradient(180deg, #c8a86b 0%, #725e3a 100%);
      -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      mask-composite: add, add;
      -webkit-background-composite: inherit;
      -webkit-mask-composite: source-over, source-over;
      mask-composite: add, add;
      // -webkit-mask-composite: destination-out;
      mask-composite: exclude;
    }
    div {
      width: 278px;
      margin-left: 90px;
      margin-top: 34px;

      @include mq("phone-wide", max) {
        margin-left: 70px;
        margin-top: 25px;
      }
    }
  }
  .pricing-plan__header-recommended {
    width: 143px;
    height: 0px;
    margin: 28px;
    margin-bottom: -20px;
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    div {
      border-radius: 6px;
      padding: 10px 15px;
      background: #5634c2;
      @include mq("phone-wide", max) {
        padding: 7px 12px;
        position: fixed;
        top: 20px;
        margin-left: 27px;
      }
      text-transform: uppercase;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 17px;
      color: #ffffff;
      text-align: center;
    }
  }

  .pricing-plan__subscribe-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-top: 2.5em;
    margin-bottom: 3em;
    @include mq("phone-wide", max) {
      margin-top: -0.6em;
    }
    button {
      cursor: pointer;
      width: 220px;
      height: 55px;
      border: none;
      outline: none;
      padding: 10px 30px;
      background: #c8a86b;
      border-radius: 8px;

      // text
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      @include mq("phone-wide", max) {
        font-size: 14px;
        width: 228px;
        height: 47px;
      }
      line-height: 24px;
      text-align: center;
      text-transform: uppercase;
      color: #000;
    }
  }
}

.pricing-main-container {
  min-height: 100vh;
  background: $main-4;
  display: flex;
  flex-direction: column;

  .carousel-swipe__instruction {
    @include mq("phone-wide", min) {
      display: none;
    }
    width: 100%;
    display: flex;
    justify-content: center;
    div {
      font-size: 12px;
    }
    img {
      margin-left: 16px;
      position: relative;
      top: -5px;
    }
  }

  .pricing-breaker-icon__container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .breaker-nation-icon2 {
      height: 79.8734359741211px;
      width: 94px;
      @include mq("phone-wide", min) {
        display: none;
      }
    }
  }

  .pricing-footer {
    margin: 5em 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .pricing-footer__container {
      display: flex;
      width: 450px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @include mq("phone-wide", max) {
        width: 223px;
      }

      .pricing-footer__rights {
        margin-top: 13px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        text-align: right;
        color: #ffffff;
        @include mq("phone-wide", max) {
          text-align: center;
          font-size: 14px;
        }
      }
    }
  }

  .pricing-top-container {
    padding: 2vh 5vw 5vh 6vw;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;

    .pricing-header {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      align-items: flex-start;
      position: relative;
      .breaker-nation-icon {
        width: 84px;
        // height: 110px;
        @include mq("phone-wide", max) {
          display: none;
        }
      }
      .signin-section {
        display: flex;
        align-items: center;
        @include mq("phone-wide", max) {
          width: 100%;
          justify-content: space-between;
        }
        .signin-section__signin-btn {
          cursor: pointer;
          border: none;
          outline: none;
          background: transparent;
          text-transform: uppercase;
          color: white;
          width: 90px;
          height: 29.7px;
          font-size: 16px;
          @include mq("phone-wide", max) {
            width: 100%;
            font-size: 16px;
            text-transform: capitalize;
          }
        }
        .signin-section__get-started {
          cursor: pointer;
          width: 180px;
          height: 44px;
          border: none;
          outline: none;
          padding: 10px 30px;
          background: #c8a86b;
          border-radius: 8px;
          margin-left: 32px;

          // text
          font-family: Montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          @include mq("phone-wide", max) {
            font-size: 16px;
            background-color: transparent;
            text-transform: capitalize;
            margin-left: 0px;
            height: 100%;
            width: 100%;
            padding: 0;
          }
          line-height: 24px;
          text-align: center;
          text-transform: uppercase;
          color: #ffffff;
        }
      }
    }
  }

  .plans-title__container {
    margin-top: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .pricing-title {
      font-style: normal;
      font-weight: 900;
      font-size: 44px;
      color: #c8a86b;
      line-height: 54px;
      text-align: center;
      margin-bottom: 0.6em;

      @include mq("phone-wide", max) {
        font-size: 30px;
        line-height: 37px;
      }
    }

    .pricing-desc {
      width: 821px;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 200%;
      text-align: center;
      color: #ffffff;
      @include mq("phone-wide", max) {
        width: 300px;
        height: 168px;
        font-size: 14px;
        line-height: 200%;
      }
    }
  }

  // plans-title__container
  .pricing-switch__container {
    margin-top: 48px;
    @include mq("phone-wide", max) {
      margin-top: 3.8em;
    }
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .pricing-annual-monthly {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 336px;
      height: 44px;
      border: 1px solid #c8a86b;
      box-sizing: border-box;
      border-radius: 10px;
      @include mq("phone-wide", max) {
        width: 295px;
        height: 44px;
      }
      .active {
        background: #c8a86b !important;
      }
      .pricing-plan__btn {
        cursor: pointer;
        border: none;
        outline: none;
        background: transparent;
        height: 100%;
        width: 100%;

        // text
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
        @include mq("phone-wide", max) {
          font-size: 14px;
        }
      }
      .pricing-plan__btn-left {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      .pricing-plan__btn-right {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }

  // Plans

  .pricing-plans {
    width: 100%;
    margin-top: 48px;
    @include mq("phone-wide", max) {
      margin-top: 2.2em;
    }
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: #ffffff;

    .pricing-plans__carousel {
      // Hide on desktop view
      display: none;

      // show on mobile view
      @include mq("phone-wide", max) {
        display: block;
        width: 100%;
      }
    }

    .pricing-plans__cards {
      // Hide desktop view of plan cards
      @include mq("phone-wide", max) {
        display: none;
      }
      .pricing-plans__container {
        display: flex;
        justify-content: center;
        align-items: flex-end;
      }
    }
  }

  .faq-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    @include mq("phone-wide", max) {
      margin-top: 2em;
    }
    .faq-title {
      color: #fff;
      margin-top: 108px;
      margin-bottom: 16px;
      height: 104.14px;
      font-style: normal;
      font-weight: 900;
      font-size: 44px;
      line-height: 54px;
      text-align: center;
      @include mq("phone-wide", max) {
        font-size: 30px;
        line-height: 36px;
      }
    }

    .faq-questions__container {
      width: 1160px;
      @include mq("phone-wide", max) {
        width: 335px;
        margin-top: 2em;
      }

      .padding-bottom-12 {
        padding-bottom: 12px !important;
      }
      .faq-question__container {
        //   padding: 23px;
        padding: 23px 40px;
        padding-right: 0px;
        cursor: pointer;
        border-radius: 10px;
        background: #222223;
        @include mq("phone-wide", max) {
          padding: 17px 17px;
          padding-right: 0px;
        }
        // padding: 11px;
        // padding-left: 42px;
        // padding-bottom: 30px;
        margin-bottom: 20px;
        .faq-question__title {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          @include mq("phone-wide", max) {
            font-size: 14px;
          }
          line-height: 22px;
          color: #c8a86b;
          //   padding: 15px 0;

          .faq-arrow {
            cursor: pointer;
            margin-right: 34px;
            img {
              width: 16px;
            }
            @include mq("phone-wide", max) {
              margin-right: 16px;
              img {
                width: 14px;
              }
            }

            .faq-arrow__down {
              transition: 0.3s all;
              transform: rotate(-180deg);
            }
            .faq-arrow__up {
              transition: 0.3s all;
              transform: rotate(-360deg);
            }
          }
        }
        .faq-question__answer-hide {
          display: none;
        }
        .faq-question__answer {
          display: block;
          font-family: Montserrat;
          font-style: normal;
          width: 956px;
          font-weight: 400;
          font-size: 15px;
          line-height: 27px;
          color: #ffffff;
          margin-top: 2px;
          @include mq("phone-wide", max) {
            font-size: 14px;
            line-height: 17px;
            width: 87%;
          }
        }
      }
    }
  }
}
