@import "../../scss/variables.scss";

.request-review-container {
  min-width: 350px;
  height: 100vh;
  background: $main-10;
  color: $main-1;
  display: flex;
  flex-direction: column;
  .request-review-header-container {
    padding: 27px 34px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .request-review-main-container {
    padding: 0 34px;
    padding-top: 0px;
    min-height: max-content;
    height: calc(100vh - 85px);
    position: relative;
    overflow-y: auto;

    .request-review-title {
      padding-top: 24px;
      padding-bottom: 12px;
      font-size: 22px;
      font-style: normal;
      font-weight: $font-weight-1;
    }
    .request-review-subtitle {
      font-weight: $font-weight-4;
      font-size: $font-size-normal;
      color: $main-2;
    }
  }

  .request-review-actions {
    padding-top: 10px;
    .request-track-button {
      background: $main-2;
      width: 100%;
      border: 0;
      border-radius: 6px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: $font-size-small;
      font-weight: $font-weight-1;
      text-transform: uppercase;
    }
    .select-track-button {
      background: $main-15;
      color: $main-1;
      width: 100%;
      border: 0;
      border-radius: 6px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: $font-size-small;
      font-weight: $font-weight-1;
      text-transform: uppercase;
    }
  }
}

.not-found-container {
  width: 180px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .not-found-text {
    padding-top: 32px;
    font-size: $font-size-medium;
    line-height: $font-size-xl;
    text-align: center;
    color: $main-5;
    padding-top: 20px;
  }
}

.track-list-container {
  padding-top: 40px;
}

.track-list-item-container {
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid $main-17;

  .track-list-item-left-container {
    display: flex;
  }

  .track-list-item-image {
    margin-left: 24px;
    width: 34px;
    height: 34px;
    border-radius: 6px;
  }

  .track-list-item-description {
    margin-left: 16px;
    .track-list-item-title {
      font-style: normal;
      font-weight: $font-weight-1;
      font-size: $font-size-normal;
      line-height: 17px;
      max-width: 132px;
      padding-bottom: 2px;
    }
    .likes {
      font-style: normal;
      font-weight: $font-weight-4;
      font-size: $font-size-small;
      line-height: 15px;
      color: $main-16;
    }
  }

  .track-list-item-amount {
    font-style: normal;
    font-weight: $font-weight-1;
    font-size: $font-size-normal;
  }

  &.disabled {
    .track-list-item-amount {
      opacity: 0.3;
    }
    .track-list-item-description {
      .likes,
      .track-list-item-title {
        opacity: 0.3;
        color: $main-1;
      }
    }

    .track-list-item-check-box {
      opacity: 0.3;
    }
  }
}
