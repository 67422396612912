@import "../../scss/variables.scss";
@import "../../scss/mixins";
.decline-btn {
    cursor: pointer;
    width: 220px;
    height: 55px;
    outline: none;
    border: none;
    background: #222223;
    border-radius: 6px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    margin-left: 2em;
    @include mq("phone-wide", max) {
        margin-left: 0em;
        margin-top: 2em;
  }
  }.hide-on-mobile {
  @include mq("phone-wide", max) {
    display: none;
  }
}
.hide-on-dekstop {
  @include mq("phone-wide", min) {
    display: none;
  }
}
.show-on-mobile {
  @include mq("phone-wide", max) {
    display: block;
  }
}

.internship-congratulations {
  background: #18181a;
  hr {
    width: 100%;
    background: rgba(238, 240, 250, 0.5);
    opacity: 0.2;
    margin: 0.5em 0;
  }
  .container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .decline{
        // width: 1218px;
        h1{
            font-family: Montserrat;
            font-style: normal;
            font-weight: 900;
            font-size: 22px;
            line-height: 151.9%;
            text-align: center;
            text-transform: uppercase;
            color: #FFFFFF;
            margin-top: 2em;
            @include mq("phone-wide", max) {
              width: 335px;
              font-size: 16px;
            }
        }
          padding: 2em 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .decline-input__container{
            @include mq("phone-wide", max) {
                width: 303px;
            }
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: center;
              span{
                font-family: Montserrat;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                color: #FFFFFF;
            }
                textarea{
                    color: white !important;
                    margin-top: 1em;
                    width: 100%;
                    background: #222223;
                    padding: 10px 20px;
                    @include mq("phone-wide", max) {
                        width: 310px;
                    }

                    border: 1px solid #C8A86B;
                    box-sizing: border-box;
                    border-radius: 8px;
                    &:focus{
                        outline: none !important;
                        border:1px solid #C8A86B;
                }
                }
          }
    }
    .payment{
        h1{
            font-family: Montserrat;
            font-style: normal;
            font-weight: 900;
            font-size: 22px;
            line-height: 151.9%;
            text-align: center;
            text-transform: uppercase;
            color: #FFFFFF;

        }
          width: 100%;
          margin-top: 2em;
          @include mq("phone-wide", max) {
            width: 335px;
        }
        //   height: 563px;
        padding: 2em 0;
        background: #222223;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    .welcome-text {
      margin-top: 3em;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .text {
        width: 948px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #ffffff;
        @include mq("phone-wide", max) {
            width: 303px;
            font-size: 14px;
            line-height: 21px;
        }
      }
      .accept-decline__container {
        margin-top: 3em;
        margin-bottom: 3em;
        display: flex;
        @include mq("phone-wide", max) {
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .accept-btn {
          cursor: pointer;
          color: #18181a;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          width: 220px;
          height: 55px;
          background: #c8a86b;
          border-radius: 6px;
          outline: none;
          border: none;
        }
        .decline-btn {
          cursor: pointer;
          width: 220px;
          height: 55px;
          outline: none;
          border: none;
          background: #222223;
          border-radius: 6px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          text-align: center;
          text-transform: uppercase;
          color: #ffffff;
          margin-left: 2em;
          @include mq("phone-wide", max) {
              margin-left: 0em;
              margin-top: 2em;
        }
        }
      }
    }
    .congratulations-wrapper {
    //   width: 1218px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .title-container {
        margin-top: 2em;
        h1 {
          font-family: Clicker Script;
          font-style: normal;
          font-weight: normal;
          font-size: 60px;
          line-height: 160%;
          text-align: center;
          color: #ffffff;
          @include mq("phone-wide", max) {
            font-size: 40px;
            line-height: 160%;
          }
        }
        .desc {
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 30px;
          line-height: 160%;
          text-align: center;
          color: #ffffff;
          @include mq("phone-wide", max) {
            width: 313px;
            font-size: 20px;
            line-height: 160%;
          }
        }
      }


      .offer-container {
        margin-top: 3em;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .enroll-text {
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #ffffff;
          margin-bottom: 1em;
          @include mq("phone-wide", max) {
            width: 250px;
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
            text-align: center;
            color: #ffffff;
          }
        }
        .offers {
          width: 100%;
          // height: 778px;
          padding: 2em 0;
          background: #222223;
          border-radius: 6px;
          @include mq("phone-wide", max) {
            width: 335px;
          }
          .header {
            padding: 3em 5em;
            @include mq("phone-wide", max) {
              padding: 0em;
            }
            .internship-program {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: space-between;
              .program-details {
                @include mq("phone-wide", max) {
                  padding: 1em;
                }
                margin-top: 1em;
                ul {
                  padding: 0;
                  li {
                    display: flex;
                    align-items: flex-start;
                    margin: 12px 0;

                    list-style: none;
                    font-family: Montserrat;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 24px;
                    color: #ffffff;
                    @include mq("phone-wide", max) {
                      font-size: 14px;
                    }
                  }
                  li:before {
                    margin-bottom: 3px;
                    color: transparent;
                    content: " ";
                    //   margin-left: -3.3em;
                    margin-right: 10px;
                    padding: 12px;
                    background: linear-gradient(180deg, #c8a86b 0%, #725e3a 100%);
                    -webkit-mask-image: url("../../assets/img/pricing/done_black.svg");
                    -webkit-mask-size: cover;
                  }
                }
              }
              .program-title {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                @include mq("phone-wide", max) {
                  flex-direction: column;
                }
                .program-title__wrap {
                  padding: 1em;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                }
                .free-banner {
                  width: 100%;
                  font-family: Montserrat;
                  font-style: normal;
                  font-weight: 600;
                  font-size: 20px;
                  line-height: 24px;
                  text-align: center;
                  color: #ffffff;
                }

                h1 {
                  font-family: Montserrat;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 32px;
                  line-height: 39px;
                  text-align: center;
                  color: #ffffff;
                  @include mq("phone-wide", max) {
                    font-size: 20px;
                    line-height: 24px;
                  }
                }
                span {
                  font-family: Montserrat;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 16px;
                  line-height: 24px;
                  color: #c8a86b;
                  @include mq("phone-wide", max) {
                    font-size: 16px;
                    line-height: 24px;
                  }
                }
              }
              .program-cost {
                h1 {
                  font-family: Montserrat;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 32px;
                  line-height: 39px;
                  color: #ffffff;
                }
              }
            }

            .show-details {
              margin-top: 1.3em;
              width: 100%;
              display: flex;
              justify-content: center;
              cursor: pointer;
              .show-details__title {
                font-family: Montserrat;
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                text-transform: uppercase;
                color: #c8a86b;
                @include mq("phone-wide", max) {
                  font-size: 14px;
                  line-height: 17px;
                }
              }
              .arrow {
                margin-left: 10px;
                @include mq("phone-wide", max) {
                  margin-bottom: 2em;
                }
                img {
                  width: 20px;
                  @include mq("phone-wide", max) {
                    width: 15px;
                    margin-bottom: 5px;
                  }
                }
                .arrow__down {
                  transition: 0.3s all;
                  transform: rotate(-180deg);
                }
                .arrow__up {
                  transition: 0.3s all;
                  transform: rotate(-360deg);
                }
              }
            }
          }

          .optional-add__container {
            margin-top: 4em;
            .optional-add {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              margin-bottom: 3em;
              h1 {
                font-family: Montserrat;
                font-style: normal;
                font-weight: 900;
                font-size: 22px;
                line-height: 151.9%;
                text-align: center;
                text-transform: uppercase;
                color: #ffffff;
                @include mq("phone-wide", max) {
                  font-size: 16px;
                }
              }
              span {
                font-family: Montserrat;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                color: #ffffff;
                @include mq("phone-wide", max) {
                  font-size: 16px;
                }
              }
            }
            .total-container {
              margin-top: 3em;
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              div {
                display: flex;
                justify-content: center;
                align-items: center;
                h1 {
                  font-family: Montserrat;
                  font-style: normal;
                  font-weight: 700;
                  font-size: 22px;
                  text-align: center;
                  text-transform: uppercase;
                  color: #ffffff;
                  padding: 0;
                  margin: 0;
                }
                span {
                  margin-left: 4em;
                  font-family: Montserrat;
                  font-style: normal;
                  font-weight: 700;
                  font-size: 22px;
                  text-align: center;
                  text-transform: uppercase;
                  color: #ffffff;
                }
              }
            }
            .addons-container {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              .second-addon {
                margin-top: 1em;
              }
              .addon-wrap {
                width: 88%;
                border: 1px solid;
                background: linear-gradient(279.64deg, #c8a86b -2.36%, #5634c2 27.27%, #5634c2 55.12%, #c8a86b 92.53%);
                border-radius: 6px;
                display: inline-block;
                font-size: 20px;
                padding: 1px;
                text-decoration: none;
                .addon {
                  background-color: #222223;

                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  padding: 1.2em 1em;
                  @include mq("phone-wide", max) {
                    padding: 1em 0.5em;
                  }
                  border-radius: 6px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  .program-details {
                    margin-top: 1em;
                    ul {
                      padding: 0;
                      li {
                        margin: 12px 0;
                        display: flex;
                        align-items: flex-start;

                        list-style: none;
                        font-family: Montserrat;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 24px;
                        color: #ffffff;
                        @include mq("phone-wide", max) {
                          font-size: 14px;
                        }
                      }
                      li:before {
                        margin-bottom: 3px;
                        color: transparent;
                        content: " ";
                        //   margin-left: -3.3em;
                        margin-right: 10px;
                        padding: 12px;
                        background: linear-gradient(180deg, #c8a86b 0%, #725e3a 100%);
                        -webkit-mask-image: url("../../assets/img/pricing/done_black.svg");
                        -webkit-mask-size: cover;
                        @include mq("phone-wide", max) {
                          font-size: 14px;
                        }
                      }
                    }
                  }
                  .first {
                    width: 100%;
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;

                    .addon-price {
                      @include mq("phone-wide", max) {
                        // margin-top: 1em;
                        margin-right: 2em;
                      }
                      .addon-price__wrap {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        width: 119px;
                        .pre {
                          font-family: Montserrat;
                          font-style: normal;
                          font-weight: 600;
                          font-size: 14px;
                          line-height: 17px;
                          color: #ffffff;
                        }
                        span {
                          font-family: Montserrat;
                          font-style: normal;
                          font-weight: 500;
                          font-size: 32px;
                          line-height: 39px;
                          color: #ffffff;
                          @include mq("phone-wide", max) {
                            font-size: 20px;
                          }
                          sup {
                            font-family: Montserrat;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 25px;
                            line-height: 24px;
                            color: #ffffff;
                            @include mq("phone-wide", max) {
                              font-size: 15px;
                            }
                          }
                        }
                      }
                      span {
                        font-family: Montserrat;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 17px;
                        color: #ffffff;
                      }
                    }
                    .checkbox-container {
                      margin-right: 10px;
                      display: block;
                      position: relative;
                      padding-left: 35px;
                      margin-bottom: 12px;
                      cursor: pointer;
                      font-size: 22px;
                      -webkit-user-select: none;
                      -moz-user-select: none;
                      -ms-user-select: none;
                      user-select: none;
                    }

                    /* Hide the browser's default checkbox */
                    .checkbox-container input {
                      position: absolute;
                      opacity: 0;
                      cursor: pointer;
                      height: 0;
                      width: 0;
                    }

                    /* Create a custom checkbox */
                    .checkmark {
                      position: absolute;
                      top: 0;
                      left: 0;
                      height: 35px;
                      width: 35px;
                      border: 1px solid #5634c2;
                      border-radius: 6px;
                    }

                    .checkbox-container input:checked ~ .checkmark {
                      background-color: #5634c2;
                    }

                    /* Create the checkmark/indicator (hidden when not checked) */
                    .checkmark:after {
                      content: "";
                      position: absolute;
                      display: none;
                    }

                    /* Show the checkmark when checked */
                    .checkbox-container input:checked ~ .checkmark:after {
                      display: block;
                    }

                    /* Style the checkmark/indicator */
                    .checkbox-container .checkmark:after {
                      left: 13px;
                      top: 7px;
                      width: 8px;
                      height: 15px;
                      border: solid black;
                      border-width: 0 3px 3px 0;
                      -webkit-transform: rotate(45deg);
                      -ms-transform: rotate(45deg);
                      transform: rotate(45deg);
                    }
                    // .custom-checkbox{
                    //     border: 1px solid rgba(86, 52, 194, 1);
                    //     -moz-appearance:none;
                    //     -webkit-appearance:none;
                    //     -o-appearance:none;
                    //     padding: 20px;
                    //     box-sizing: border-box;
                    //     border-radius: 6px;
                    // }

                    .addon-title__container {
                      width: 100%;
                      .tittle-arrow__container {
                        cursor: pointer;
                        display: flex;
                        justify-content: center;
                        align-items: flex-start;
                        flex-direction: column;
                        width: 100%;

                        .container-wrap1 {
                          hr {
                            width: 127%;
                            margin-right: 43px;
                            margin-top: 1em;
                          }
                          width: 100%;
                          display: flex;
                          justify-content: space-between;
                          align-items: center;
                          @include mq("phone-wide", max) {
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                          }
                          .container-wrap2 {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: flex-start;

                            .addon-desc {
                              span {
                                font-family: Montserrat;
                                font-style: normal;
                                font-weight: normal;
                                font-size: 16px;
                                line-height: 24px;
                                color: #c8a86b;
                                @include mq("phone-wide", max) {
                                  font-size: 16px;
                                }
                              }
                            }
                            .title-wrapper {
                              display: flex;
                              justify-content: center;
                              align-items: flex-start;

                              h1 {
                                font-family: Montserrat;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 22px;
                                line-height: 27px;
                                text-align: center;
                                color: #ffffff;
                                @include mq("phone-wide", max) {
                                  font-size: 16px;
                                  width: 194px;
                                }
                              }
                              .arrow {
                                margin-left: 10px;
                                img {
                                  width: 20px;
                                  color: white;
                                }
                                .arrow__down {
                                  transition: 0.3s all;
                                  transform: rotate(-180deg);
                                }
                                .arrow__up {
                                  transition: 0.3s all;
                                  transform: rotate(-360deg);
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .faq-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        @include mq("phone-wide", max) {
          margin-top: 2em;
        }
        .faq-title {
          color: #fff;
          height: 50.14px;
          font-style: normal;
          font-weight: 900;
          font-size: 22px;
          line-height: 27px;
          text-align: center;
        }

        .faq-questions__container {
            width: 100%;
          @include mq("phone-wide", max) {
            width: 335px;
            margin-top: 2em;
          }

          .padding-bottom-12 {
            padding-bottom: 12px !important;
          }
          .faq-question__container {
            //   padding: 23px;
            padding: 23px 40px;
            padding-right: 0px;
            cursor: pointer;
            border-radius: 10px;
            background: #222223;
            @include mq("phone-wide", max) {
              padding: 17px 17px;
              padding-right: 0px;
            }
            // padding: 11px;
            // padding-left: 42px;
            // padding-bottom: 30px;
            margin-bottom: 20px;
            .faq-question__title {
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: flex-start;
              font-family: Montserrat;
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              @include mq("phone-wide", max) {
                font-size: 14px;
              }
              line-height: 22px;
              color: #c8a86b;
              //   padding: 15px 0;

              .faq-arrow {
                cursor: pointer;
                margin-right: 34px;
                img {
                  width: 16px;
                }
                @include mq("phone-wide", max) {
                  margin-right: 16px;
                  img {
                    width: 14px;
                  }
                }

                .faq-arrow__down {
                  transition: 0.3s all;
                  transform: rotate(-180deg);
                }
                .faq-arrow__up {
                  transition: 0.3s all;
                  transform: rotate(-360deg);
                }
              }
            }
            .faq-question__answer-hide {
              display: none;
            }
            .faq-question__answer {
              display: block;
              font-family: Montserrat;
              font-style: normal;
              width: 1067px;
              font-weight: 400;
              font-size: 15px;
              line-height: 27px;
              color: #ffffff;
              margin-top: 2px;
              @include mq("phone-wide", max) {
                font-size: 14px;
                line-height: 17px;
                width: 87%;
              }
            }
          }
      }}
  }
}
