@import "../../../scss/variables.scss";

.review-goal-container {
  flex: 1;
  height: 270px;
  width: 560px;
  margin: 20px 0;
  background: $main-10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;

  @media (max-width: 670px) {
    text-align: center;
    display: block;
    width: 100%;
    height: auto;
    padding-bottom: 24px;
  }

  .review-progress {
    width: 170px;
    height: 170px;
    margin: 0 54px;
    @media (max-width: 670px) {
      margin: 20px 0;
      display: inline-block;
    }
  }

  .review-info {
    width: 240px;
    text-align: center;
    padding: 0 20px;
    @media (max-width: 670px) {
      margin: 0 auto;
      display: block;
    }

    .title {
      color: $main-2;
      font-weight: $font-weight-1;
      font-size: $font-size-normal;
      margin: 0 0 16px 0;
    }

    .completed .title {
      color: #42ae55; // not defined in variable
    }

    .desc {
      font-weight: 300; // not defined in variable
      font-size: $font-size-small;
      color: rgba($main-1, 0.5);
      line-height: 20px;
    }
  }
}

.preview-again-button {
  margin-top: 24px;
  background: $main-10;
  border: 1px solid $main-2;
  font-size: $font-size-small;
  font-weight: $font-weight-1;
  padding: 15px 0;
  width: 100%;
  color: $main-1;
  border-radius: 4px;
}
