@import "../../scss/variables.scss";
@import "../../scss/mixins";

.musician-main-container {
  min-height: 100vh;
  background: $main-4;
  display: flex;
  flex-direction: column;

  .musician-top-container {
    min-height: 100vh;
    // padding: 2vh 16vw 5vh 13vw;
    padding: 2vh 5vw 5vh 6vw;
    background-image: url("../../assets/img/musician/musicians\ bg.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    .otp-container{
        margin-bottom: 100px;
    }
    .review-section{
        margin-top: 50px;
        width: 100%;
        // width: 150%;
        display: flex;
        justify-content: flex-start;
        margin-bottom: 10px;
        .review-section__last-two-cards{
            display: flex;
        }
        .review-section__last-two-cards-scroll{
            display: flex;
            flex-direction: column;
        }
        @include mq("desktop-small", max) {
            flex-direction: column;
            align-items: flex-start;
            .review-section__last-two-cards{
                display: none;
            }
        }
        @include mq("desktop-small", min) {
            align-items: flex-start;
            .review-section__last-two-cards-scroll{
                display: none;
            }
        }
    }
    .congratulation-payment-section{
            margin-top: 50px;
            width: 100%;
            // width: 150%;
            display: flex;
            justify-content: flex-start;
            margin-bottom: 10px;
            @include mq("desktop", max) {
                flex-direction: column;
                align-items: center;

            }
    }

    .musician-header__right-part{
         display: flex;
         justify-content: center;
         align-items: flex-start;
         .musician-header__right-part-title{
            cursor: pointer;
            font-weight: 600;
            font-size: 20px;
            line-height: 170%;
            text-align: right;
            text-transform: uppercase;
            color: #FFFFFF;
         }
         .musician-header__right-part-btn{
             cursor: pointer;
             background: none;
             outline: none;
             border: none;
             margin-left: 40px;
             .musician-header__right-part-btn__icon{
                padding: 0 10px;
             }
            .musician-header__right-part-btn__text{
                font-weight: 600;
                font-size: 20px;
                line-height: 170%;
                text-align: right;
                text-transform: uppercase;
                color: #FFFFFF;
            }
         }
    }
    .musician-header {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      position: relative;
      .musician-tabs {
        display: flex;
        color: $main-1;
        position: absolute;
        right: 0;
        cursor: pointer;
      }
      .musician-header-tags {
        font-size: $font-size-xl;
        margin-left: 5vw;
      }
      .musician-tag-disable {
        opacity: 0.3;
      }
    }
    .beta-txt-container {
      max-width: 1000px;
      .beta-txt-sub-container {
        display: flex;
        flex-wrap: wrap;
        @media (max-width: 680px) {
            justify-content: left;
        }
      }
    }
    .breaker-nation-icon {
      width: 130px;
    //   margin-left: 5%;
      height: 130px;
      margin-bottom: 5vw;
    }
    .before-you-txt {
      font-size: $font-size-xl;
      color: $main-1;
      line-height: 200%;
      margin-top: 35px;
      max-width: 800px;
    }
    .congratulation-txt {
      font-size: 18px;
      text-align: left;
      color: $main-1;
      line-height: 200%;
      margin-top: 35px;
      max-width: 1000px;
      font-weight: 500;
    }
    .confirmation-txt {
      font-size: 18px;
      color: $main-1;
      line-height: 200%;
      margin-top: 35px;
      width: 900px;
      max-width: 1000px;
      font-weight: 500;
      text-align: left;
      @media (max-width: 890px) {
          width: 600px;
      }
      @media (max-width: 690px) {
          width: 400px;
      }
      @media (max-width: 460px) {
          width: 309px;
      }
      .confirmation-txt__dashboard-txt{
        color: $main-2;
        text-decoration: underline;
      }
    }
    .confirmation-apps__container{
        display: flex;
        margin-top: 10px;
        .app{
            margin-right: 10px;
        }
    }
    .confirmation-letter__your-truly{
        font-size: 18px;
      color: $main-1;
      line-height: 200%;
      margin-top: 35px;
      max-width: 1000px;
      font-weight: 500;
      text-align: left;
    }
    .lets-start-button {
      background: linear-gradient(180deg, #C8A86B 0%, #725E3A 100%);
      border-radius: 8px;
      color: $main-1;
      height: 50px;
      width: 250px;
      margin-top: 40px;
      text-transform: uppercase;
      font-size: $font-size-xl;
      font-weight: bold;
      outline: none;
      @include mq('tablet',max){
        margin-top: 130px;
    }
    }
    @media (max-width: 600px) {
      .musician-header {
        align-items: center;
        flex-direction: column;
        .musician-tabs {
          right: unset;
          align-items: center;
          width: fit-content;
          top: 10vw;
        }
        .musician-header-tags {
          font-size: $font-size-xl;
          margin-left: 5vw;
        }
        .musician-tag-disable {
          opacity: 0.3;
        }
      }
      .breaker-nation-icon {
        width: 129px;
        margin-left: unset;
        height: 110px;
        margin-bottom: 15vw;
        margin-top: 15vw;
      }
      .beta-txt-container {
        // text-align: center;
        max-width: 500px;
      }
      .beta-txt {
        margin: 0px;
        font-size: $font-size-28;
        line-height: 34px;
        // text-align: center;
        text-transform: uppercase;
      }

      .before-you-txt {
        font-size: $font-size-normal;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 200%;
        color: #FFFFFF;
        margin-bottom:20px;
      }
      .lets-start-button {
        align-self: center;
        margin-bottom: 16vw;
      }
    }
  }
  @media (max-width: 600px) {
    .musician-top-container {
      padding: 5vw;
    }
    .musician-middle-container {
      padding: 5vw;
    }
  }
  .main-musician-middle-container{
      padding:40px;
      display: flex;
      justify-content: space-evenly;
      @include mq("desktop-small", max) {
          flex-direction: column;
          align-items: center;
      }
      .how-it-works__container{
        width: 507px;
        height: 100%;
        background: #18181A;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
        border-radius: 10px;
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        @include mq("tablet", max) {
            width: 100%;
        }
        .how-it-works__points{
            padding: 0 50px 0 50px;
            display: flex;
            flex-direction: column;
            span{
                margin: 30px 0;
                font-family: Montserrat;
                font-style: normal;
                font-weight: bold;
                font-size: 22px;
                @include mq("tablet", max) {
                    font-size: 15px;
                    margin: 15px 0;
                }
                @include mq("desktop", max) {
                    font-size: 20px;
                    margin: 20px 0;
                }
                line-height: 200%;
                color: #FFFFFF
            }
            .how-it-works__title{
                color:#4C388E;
            }
        }
        // padding: 5vh 16vw 5vh 16vw;
      }
  }
  .musician-middle-container {
    min-height: 1200px;
    // padding: 5vh 16vw 5vh 16vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    @include mq('phone',max){
        width: 340px;
    }
    .send-music-text {
        background-color: transparent;
        background-image: linear-gradient(180deg, #C8A86B 0%, #725E3A 100%);
        background-size: 100%;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-text-fill-color: transparent;
        font-size: $font-size-32;
        color: $main-1;
        font-weight: bold;
    }
    .musician-upload-section {
      display: flex;
      min-height: 250px;
      flex-direction: column;
      justify-content: space-evenly;
      .yt-check-box{
          margin-left: 80px;
      }
      .upload-container{
          display: flex;
          .file-upload-box__container{
              margin-right: 40px;
              margin-top: 20px;
              @include mq('phone',max){
                margin: 10px 0;
            }
          }
          @include mq('tablet-small',max){
              flex-direction: column;
              justify-content: space-between;
          }
      }
    }
    .upload-track {
      text-transform: uppercase;
      font-weight: 600;
      font-size: $font-size-xl;
      color: $main-1;
    }
    .upload-checkbox-type-container {
      margin-top:10px;
      display: flex;
      flex-direction: row;
      .uncheck{
          background: transparent !important
      }
    }
    .check-box-outer {
      height: 20px;
      width: 20px;
      border: 1px solid $main-5;
      border-radius: 8px;
      justify-content: center;
      align-items: center;
      display: flex;
      .check-box-inner {
        background: $main-2;
        border-radius: 50%;
        height: 16px;
        width: 16px;
      }
    }
    .upload-txt {
      font-weight: 500;
      color: $main-1;
      margin-left: 15px;

    }
    .file-upload-box {
      border: 1px dashed $main-2;
      box-sizing: border-box;
      border-radius: 8px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 15px;
      text-align: center;
      .drag-txt {
        color: $main-1;
      }
      .file-upload-txt {
        color: $main-2;
      }
      .attach-container {
        display: flex;
        margin-top: 10;
      }
      .file-size-txt {
        color: $main-1;
        font-size: $font-size-small;
      }
      .you-may-attach {
        color: $main-1;
        font-size: $font-size-small;
        opacity: 0.5;
      }
    }
    .file-upload-box-2 {
      display: flex;
      align-items: center;
      color: $main-1;
      .delete-container {
        width: 84px;
        height: 84px;
        background: $main-3;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .file-name-txt {
        margin-left: 15px;
      }
    }
    .track-info-container {
      display: flex;
      min-height: 130px;
      flex-direction: column;
      justify-content: space-between;
      .email-input{
        width: 250px;
        height: 44px;
        left: 414px;
        top: 508px;
        background: $main-3;
        border-radius: 8px;
        color: $main-1;
        margin-top: 10px;
        border: none;
        padding-left: 15px;
        outline: none;
        @include mq("desktop-small", max) {
            width: 100%;
        }
      }
      .firstname-lastname-username__container{
          display: flex;
      }
      .track-info {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: $font-size-xl;
        line-height: 200%;
        color: $main-1;
        @media (max-width: 600px) {
            font-size: $font-size-l

        }
      }
      .track-info-sub-container {
        display: flex;
        flex-wrap: wrap;
        .input-container {
          display: flex;
          flex-direction: column;
          margin-right: 10px;
        }
        .track-input-label {
          font-style: normal;
          font-weight: normal;
          font-size: $font-size-small;
          line-height: 15px;
          color: $main-2;
          margin-top: 10px;
        }
        .phone-number-container {
          display: flex;
          .dropdown-icon {
            width: 15px;
            height: 15px;
          }
          .MuiInputBase-root {
            width: 85px;
            height: 47px;
            background: $main-3;
            border-radius: 8px !important;
            color: $main-1 !important;
            border: none !important;
            margin-right: 10px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            padding-right: 15px;
            margin-top: 0 !important;
            outline: none !important;
          }
          .MuiSelect-outlined.MuiSelect-outlined {
            padding: 0;
            padding-left: 15px;
            padding-right: 0px !important;
          }
          .MuiFormControl-root {
            margin: 10px 0px !important;
            min-width: 50px !important;
          }
        }
        .country-code-selector {
          width: 100px;
          height: 44px;
          left: 414px;
          top: 508px;
          background: $main-3;
          border-radius: 8px;
          color: $main-1;
          margin-top: 10px;
          border: none;
          margin-right: 10px;
          display: flex;
          justify-content: space-around;
          align-items: center;
        }
        .track-info-input {
          width: 250px;
          height: 44px;
          left: 414px;
          top: 508px;
          background: $main-3;
          border-radius: 8px;
          color: $main-1;
          margin-top: 10px;
          border: none;
          padding-left: 15px;
          outline: none;
        }
        .genres-tag {
          background: $main-3;
          border-radius: 8px;
          padding: 15px;
          color: $main-1;
          margin-right: 10px;
          margin-top: 10px;
        }
      }
      .phone-verify-txt-container {
        display: flex;
        margin-top: 25px;
        margin-bottom: 25px;
        .content-verify {
          font-size: $font-size-normal;
          line-height: 17px;
          color: $main-1;
          opacity: 0.5;
          margin-left: 10px;
          width: 300px;
        }
      }
    }
    .submit-button {
        background: linear-gradient(180deg, #C8A86B 0%, #725E3A 100%);
        border-radius: 8px;
      box-sizing: border-box;
      color: $main-1;
      font-weight: 600;
      font-size: $font-size-xl;
      line-height: 24px;
      text-align: center;
      text-transform: uppercase;
      width: 320px;
      height: 60px;
      margin-top: 25px;
      outline: none;
    }
    .fileInput {
      display: none;
    }
  }
  @media (max-width: 600px) {
    .musician-middle-container {
      padding: 8vw;
      .send-music-text {
        font-size: $font-size-xl;
        font-weight: 600;
        margin-top: 15px;
      }
      .upload-track {
        margin-top: 20px;
        line-height: 200%;
        font-size: $font-size-l
      }
      .track-info-container {
        margin-top: 25px;
      }
      .track-info-sub-container {
        .input-container {
          width: 100%;
        }
        .country-code-selector {
          width: 25% !important;
        }
        .track-info-input {
          width: calc(100% - 15px) !important;
        }
        .track-info-phone {
          width: calc(70% - 15px) !important;
        }
      }
      .submit-button {
        width: 100%;
        margin-bottom: 60px;
        margin-top: 35px;
      }
    }
  }

  .musician-bottom-container {
    // min-height: 500px;
    // padding: 5vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: no-repeat;
    // background-image: url("../../assets/img/musician/musician2bg.png");
    align-items: center;
    .music-fans-txt {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: $font-size-34;
      line-height: 41px;
      text-align: center;
      color: $main-1;
      margin-top: 25px;
    }
    .level-up-txt {
      max-width: 349px;
      font-size: $font-size-xl;
      line-height: 200%;
      text-align: center;
      color: $main-1;
      margin-top: 40px;
    }
    .know-more-button {
      border: 1px solid $main-2;
      border-radius: 50px;
      color: $main-1;
      background: transparent;
      height: 50px;
      width: 250px;
      margin-top: 20px;
      text-transform: uppercase;
      font-size: $font-size-xl;
      font-weight: bold;
      outline: none;
    }
    .bottom-inner-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .bottom-sub-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
      .icon-container {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        width: 150px;
      }
      .right-reserved-txt {
        font-family: Montserrat;
        font-size: $font-size-small;
        line-height: 200%;
        text-align: center;
        margin-top: 15px;
        margin-bottom: 25px;
        color: $main-1;
      }
    }
  }
  @media (max-width: 600px) {
    .musician-bottom-container {
      .music-fans-txt {
        font-size: $font-size-xl;
        line-height: 24px;
      }
      .level-up-txt {
        font-size: $font-size-normal;
        line-height: 200%;
      }
    }
  }
  .beta-txt {
      text-align: left;
    max-width: 800px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 900;
    font-size: 44px;
    line-height: 54px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    background-color: $main-1;
    background-image: linear-gradient(180deg, #C8A86B 0%, #725E3A 93.21%);

    // background-image: linear-gradient(120deg, $main-1, #101010b3);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }
}

.role-main-container {
  background: #18181a;
  min-width: 300px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  .close-icon {
    margin-bottom: 30px;
  }
  .role-header {
    font-weight: 600;
    font-size: $font-size-xl;
    line-height: 200%;
    color: $main-1;
  }
  .styles-container {
    margin-top: 25px;
    overflow: auto;
    max-height: calc(100vh - 270px);
  }
  .checkbox-label {
    font-size: 16px;
    line-height: 20px;
    color: $main-1;
    margin-left: 10px;
  }
  .done-button {
    background: $main-2;
    border: 1px solid $main-2;
    box-sizing: border-box;
    border-radius: 8px;
    color: $main-1;
    font-size: $font-size-15;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    height: 60px;
    margin-top: 25px;
    outline: none;
  }
  .done-button-disable {
    background: $main-2;
    border: 1px solid $main-2;
    box-sizing: border-box;
    border-radius: 8px;
    color: $main-1;
    font-size: $font-size-15;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    height: 60px;
    margin-top: 25px;
    opacity: 0.5;
  }
  .sub-role-container {
    display: flex;
    flex-direction: row;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgb(34, 34, 35);
    align-items: center;
  }
}
@media (max-width: 600px) {
  .role-main-container {
    width: calc(100vw - 50px);
    min-width: 250px;
  }
}

.MuiDrawer-paper {
  background: #18181a !important;
}

.landing-accept-cookies-container {
  position: fixed;
  color: $main-1;
  width: calc(100% - 30px);
  min-height: 120px;
  bottom: 0;
  background: #18181af0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 15px;
  z-index: 999;
  .landing-accept-cookies-txt {
    font-size: $font-size-normal;
    line-height: 140%;
    max-width: 650px;
  }
  .landing-acpt-cook-btn-cont {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-left: 10px;
    flex-direction: row;

    .landing-acpt-cook-btn {
      width: 150px;
      min-width: 150px;
      height: 48px;
      bottom: 36px;
      background: $main-7;
      border-radius: 8px;
      border: none;
      color: $main-1;
      outline: none;
      margin-top: 10px;
      text-transform: uppercase;
      font-weight: 500;
    }
    .landing-i-acpt-cook-btn {
      width: 150px;
      min-width: 150px;
      height: 48px;
      bottom: 36px;
    //   background: linear-gradient(180deg, #C8A86B 0%, #725E3A 100%) !important;
      background: $main-2;
      border-radius: 8px;
      border: none;
      color: $main-4;
      margin-left: 15px;
      margin-top: 10px;
      outline: none;
      text-transform: uppercase;
      font-weight: 600;
    }
  }
}

.cookie-main-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 20px;
  max-width: 400px;
  .close-icon {
    margin-bottom: 30px;
  }
  .cookie-headers {
    font-weight: 600;
    font-size: $font-size-xl;
    line-height: 24px;
    color: $main-1;
    margin-top: 20px;
  }
  .cookie-content {
    font-size: $font-size-normal;
    line-height: 140%;
    color: $main-1;
    margin-top: 20px;
    opacity: 0.8;
  }
  .always-active {
    margin-top: 10px;
    font-size: $font-size-normal;
    line-height: 17px;
    color: $main-2;
  }
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .Component-switchBase-2.Component-checked-5 + .Component-track-4 {
    background-color: $main-2 !important;
  }
  .Component-track-4 {
    background-color: $main-6;
  }
  .personalize-choice-btn-2 {
    width: 90%;
    height: 48px;
    background: $main-2;
    border-radius: 8px;
    color: $main-1;
    align-self: center;
    margin-top: 50px;
    border: none;
  }
}

.otp-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 20px;
  max-width: 400px;
  .close-icon {
    margin-bottom: 30px;
  }
  .phone-number-header {
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
    color: $main-1;
    margin-top: 20px;
  }
  .text-container {
    display: flex;
    justify-content: space-between;
    .code-txt {
      margin-top: 20px;
      font-size: $font-size-small;
      line-height: 15px;
      color: $main-2;
    }
    .code-error-txt {
      margin-top: 20px;
      font-size: $font-size-small;
      line-height: 15px;
      color: $main-8;
    }
  }
  .verification-code-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    .verification-code-input {
      width: 60px;
      margin: 15px 10px 0px 0px;
      background: $main-3;
      border-radius: 10px;
    }
    .MuiOutlinedInput-input {
      color: $main-1;
      text-align: center;
    }
    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type="number"] {
      -moz-appearance: textfield;
    }
    .MuiOutlinedInput-input {
      padding: 12px;
    }
  }
  .landing-acpt-cook-btn {
    min-width: 45%;
    height: 48px;
    bottom: 36px;
    background: $main-7;
    border-radius: 8px;
    border: none;
    color: $main-1;
    outline: none;
    margin-top: 10px;
    font-weight: 600;
  }
  .landing-i-acpt-cook-btn {
    min-width: 45%;
    height: 48px;
    bottom: 36px;
    // background: linear-gradient(180deg, #C8A86B 0%, #725E3A 100%) !important;
    background: $main-2;
    border-radius: 8px;
    border: none;
    color: $main-4;
    margin-top: 10px;
    font-weight: 600;
    outline: none;
  }
}

.success-main-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 20px;
//   max-width: 400px;
  min-width: 350px;
  width: 350px;
  margin: 0 auto;
  height: 100vh;
  justify-content: flex-end;
  align-items: center;
 .drawer-bottom__container{
        margin-top: 235px;
          .desc-container{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
          .rights{
            margin-top: 30px;
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 15px;
            display: flex;
            align-items: flex-end;
            text-align: center;
            letter-spacing: 0.05em;

            color: #C8A86B;
          }
      }
  .close-icon {
    margin-bottom: 30px;
    min-height: 30px;
    position: absolute;
    top: 25px;
    left: 25px;
  }
  .success-img {
    height: 129px;
    width: 100px;

  }
  .submission-message {
    font-size: 16px;
    line-height: 200%;
    text-align: center;
    color: $main-1;
    margin-top: 20px;
  }
  .success-message {
    font-weight: 600;
    font-size: $font-size-xl;
    line-height: 24px;
    text-align: center;
    color: $main-1;
    margin-top: 10px;
  }
  .landing-acpt-cook-btn {
    width: 90%;
    height: 48px;
    bottom: 36px;
    font-weight: 600;
    background: $main-7;
    border-radius: 8px;
    border: none;
    color: $main-1;
    outline: none;
    margin-top: 10px;
  }
}
.selected {
  background: $main-2 !important;
}

.error-border {
  border: 1px solid $main-8 !important;
}
.error-border-dashed {
  border: 1px dashed $main-8 !important;
}
.error-text {
  color: $main-8 !important;
}
.MuiMenu-paper {
  background: $main-3 !important;
  color: $main-1 !important;
}

.review-card{
    margin-right: 15px;

    .review-card__container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 300.18px;
        @include mq('tablet',max){
            width: 100%;
        }
        height: 122.16px;
        position: relative;
        padding: 10px 20px;
        margin: 20px 0;
        font-size: 15px;
        &::before{
          content:"";
          position:absolute;
          top:-11px;
          left:0;
          right:0;
          bottom:-19px;
          border-radius: 8px;
          padding:2px;
          background:linear-gradient(180deg, #C8A86B 0%, #18181A 100%);
         -webkit-mask:
            linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
         -webkit-mask-composite: destination-out;
         mask-composite: exclude;
    }
        .review-card__container-text{
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            // line-height: 150%;
            text-align: center;
            color: #FFFFFF;
        }
    }
}

.how-it-works-review-card{
    margin: 0 30px;
    .how-it-works-review-card__container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // width: 405.18px;
        width: 100%;
        position: relative;
        padding: 20px 10px;        // margin:5px;
        font-size: 15px;
        &::before{
          content:"";
          position:absolute;
          top:0;
          left:0;
          right:0;
          bottom:0;
          border-radius: 8px;
          padding:2px;
          background:linear-gradient(180deg,#4C388E 0%, #18181A 80%);
         -webkit-mask:
            linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
         -webkit-mask-composite: destination-out;
         mask-composite: exclude;
    }
        .how-it-works-review-card__container-text{
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 150%;
            text-align: center;
            color: #FFFFFF;
            margin: 20px 0;
        }
    }
}

.payment-plan__container{
    margin-right: 40px;
    // @include mq("desktop", max) {
        // }
        @include mq("tablet-small", max) {
            margin-right: 0px;
        width: 100%;

    }
    margin-bottom: 40px;
    width: 527px;
    padding: 20px;
    background: #18181A;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .payment-plan__title{
        padding-top: 10px;
        font-family: Montserrat;
        font-style: normal;
    font-weight: 900;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    background-image: linear-gradient(180deg, #C8A86B 0%, #725E3A 93.21%);

    // background-image: linear-gradient(120deg, $main-1, #101010b3);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    margin: 0 auto;
    margin-bottom: 10px;

}
    .about-plan__container{
        margin-bottom: 10px;
        padding: 10px;

    ul{
        li{
            // margin: 10px 0;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 150%;
            color: #FFFFFF;
        }
    }

    span{
        margin: 10px 0;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 150%;
        color: #FFFFFF;
    }
}
    button{
        cursor: pointer;
        background: linear-gradient(180deg, #C8A86B 0%, #725E3A 100%);
        border-radius: 8px;
        color: $main-1;
      padding: 10px 20px;
      text-transform: uppercase;
      width: 100%;
      font-size: $font-size-l;
      font-weight: bold;
      outline: none;
      margin: 0 10px;
      //   margin:0 auto;
    }
    .payment-plan__btn-container{
        margin-top: auto;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
