@import "../../../scss/variables.scss";

.influencer-waiting-card {
  height: 250px;
  width: 560px;
  display: flex;
  align-items: center;

  @media (max-width: 987px) {
    width: auto;
    height: auto;
    padding-bottom: 24px;
  }

  &-title {
    display: flex;
    align-items: center;

    span {
      margin-left: 12px;
      font-size: 22px;
      font-weight: 500;
    }
  }

  &-description {
    padding: 34px 0;
    font-size: $font-size-normal;
    color: rgba($main-1, 0.5);
  }

  .influencer-description-and-action {
    display: block;

    @media (max-width: 987px) and (min-width: 670px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .request-button {
    width: 230px;
    height: 45px;
    background-color: $main-14;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .request-review-txt {
      font-weight: $font-weight-1;
      font-size: $font-size-small;
      text-align: center;
      text-transform: uppercase;
    }

    @media (max-width: 670px) {
      width: 100%;
    }
  }
}
