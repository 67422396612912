@import "../../../scss/variables.scss";

.track-details-comment-card {
  padding: 24px;
  min-height: 380px;
  width: 270px;
  margin-right: 20px;
  margin-bottom: 20px;
  box-sizing: border-box;
  background: $main-10;
  border-radius: 6px;

  @media (max-width: 670px) {
    margin-right: 0;
    width: 100%;
  }

  .comment-heading {
    display: flex;
    align-items: center;
    margin-bottom: 28px;
    img {
      width: 34px;
      height: 34px;
      border-radius: 50%;
      margin-right: 12px;
      object-fit: cover;
    }
    .username {
      font-weight: $font-weight-1;
      font-size: $font-size-small;
      margin-bottom: 4px;
    }
    .comment-date {
      font-size: $font-size-small;
      font-weight: $font-weight-4;
      color: $main-2;
    }
  }

  .track-review-pending {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 40px);
    .pending {
      width: 90px;
      height: 24px;
      border: 1px solid $main-2;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-size: 10px;
      line-height: 12px;
      text-transform: uppercase;
    }
  }

  .track-rating {
    padding-bottom: 34px;
    &-title {
      font-size: $font-size-small;
      font-weight: $font-weight-4;
      color: rgba($main-1, 0.5);
      margin-bottom: 16px;
    }
  }
  .track-promote-playlist {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
    &-key {
      color: rgba($main-1, 0.5);
      font-size: $font-size-small;
      font-weight: $font-weight-4;
    }
    &-value {
      font-size: $font-size-normal;
      font-weight: 500;
    }
  }

  .track-detail-comment-text {
    padding-top: 8px;
    font-size: $font-size-normal;
    font-weight: 300;
    line-height: 24px;
  }
  .track-detail-comment-read-more {
    color: $main-2;
    font-size: $font-size-normal;
    font-weight: $font-weight-1;
    padding-top: 24px;
    cursor: pointer;
  }

  .influencer-feedback {
    .subtitle {
      padding: 22px 0 16px 0;
      font-size: 12px;
      font-weight: 400;
      color: $main-5;
    }
  }

  .rate-review-button {
    padding: 24px 0;
    .rate-review {
      min-width: unset;
      width: 100%;
      height: 44px;
      border: 1px solid #c8a86b;
      background: transparent;
      box-sizing: border-box;
      border-radius: 6px;
      color: $main-1;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      cursor: pointer;
    }
  }
}
