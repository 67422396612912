@import "../../scss/variables.scss";
@import "../../scss/mixins";

.error-text {
  color: $main-8 !important;
}
.upload-title {
  font-size: 22px;
  font-weight: 900;
  line-height: 26px;
  padding-top: 34px;
  padding-bottom: 12px;
}
.write-us-email {
  margin-top: 20px;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  a {
    color: #c8a86b;
  }
}
.apply {
  hr {
    width: 100%;
    background: rgba(238, 240, 250, 0.5);
    opacity: 0.2;
    margin: 4em;
    @include mq("phone-wide", max) {
      margin: 2em;
      text-align: center;
      font-size: 16px;
      line-height: 20px;
    }
  }
  background: #18181a;
  // height: 100vh;
  .main-container {
    width: 100%;
    margin-top: 5em;
    display: flex;
    flex-direction: column;
    align-items: center;
    .container {
      width: 1065px;
      @include mq("phone-wide", max) {
        width: 320px;
      }
      margin: 0 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      h1 {
        color: #ffffff;
        font-weight: 700;
        font-size: 22px;
        line-height: 27px;
        text-transform: uppercase;
        @include mq("phone-wide", max) {
          text-align: center;
          font-size: 16px;
          line-height: 20px;
        }
      }
      .title-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-family: Montserrat;
        font-style: normal;

        p {
          margin-top: 1.5em;
          color: #ffffff;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          @include mq("phone-wide", max) {
            text-align: center;
            font-size: 16px;
            line-height: 20px;
          }
        }
      }
      .resume-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .add-container {
          margin: 1em 0;
          width: 100%;
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .cover-letter {
            margin-top: 1em;
            width: 100%;
            background: #222223;
            border: 0.25px solid #c8a86b;
            box-sizing: border-box;
            border-radius: 8px;
            padding: 20px;
            &:focus {
              outline: none !important;
            }
          }
          .file-dropper {
            width: 100%;
            padding-top: 40px;
            @include mq("phone-wide", max) {
              padding-top: 20px;
            }

            &.error {
              .file-dropper-wrapper {
                border-color: $main-8;
              }
            }

            &-title {
              font-size: $font-size-medium;
              font-weight: $font-weight-4;
              line-height: 32px;
              span {
                color: $main-2;
                font-weight: $font-weight-1;
              }
            }

            &-subtitle {
              font-size: $font-size-small;
              font-weight: 300;
              color: rgba($main-1, 0.5);
              line-height: 24px;
              span {
                color: $main-1;
                font-weight: $font-weight-1;
              }
            }
          }
          .file-dropper-wrapper {
            border: 1px dashed;
            border-color: rgba($main-2, 0.5);
            height: 84px;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;

            &.big {
              height: 200px;
            }
          }
        }
      }
      .social-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h1 {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 900;
          font-size: 22px;
          line-height: 151.9%;
          text-transform: uppercase;
          color: #ffffff;
          @include mq("phone-wide", max) {
            text-align: center;
            font-size: 16px;
            line-height: 20px;
          }
        }
        .social-media-input__container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          .two-social-media__wrapper {
            display: flex;
            justify-content: space-between;
            @include mq("phone-wide", max) {
              flex-direction: column;
            }
            .social-media__input {
              margin: 15px 20px;
              font-family: Montserrat;
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 24px;
              color: #ffffff;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              input {
                margin-top: 10px;
                background: #222223;
                width: 517px;
                @include mq("phone-wide", max) {
                  width: 303px;
                }
                padding: 10px 20px;

                border: 0.25px solid #c8a86b;
                box-sizing: border-box;
                border-radius: 8px;
                &:focus {
                  outline: none !important;
                }
              }
            }
          }
        }
      }

      .interview-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h1 {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 900;
          font-size: 22px;
          line-height: 151.9%;
          text-transform: uppercase;
          color: #ffffff;
          @include mq("phone-wide", max) {
            text-align: center;
            font-size: 16px;
            line-height: 20px;
          }
        }
        .question-container {
          // margin: 10px 20px;
          margin: 4em 0;
          @include mq("phone-wide", max) {
            margin: 2em 0;
          }
          width: 100%;
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .gold-letters {
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #c8a86b;
          }
          textarea {
            color: white !important;
            margin-top: 1em;
            width: 100%;
            background: #222223;
            padding: 15px 20px;

            border: 0.25px solid #c8a86b;
            box-sizing: border-box;
            border-radius: 8px;
            &:focus {
              outline: none !important;
            }
          }
        }
        button {
          width: 220px;
          height: 55px;
          background: #c8a86b;
          border-radius: 6px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          text-align: center;
          text-transform: uppercase;
          color: #18181a;
          border: none;
          outline: none;
          cursor: pointer;
        }
      }

      .faq-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        @include mq("phone-wide", max) {
          margin-top: 2em;
        }
        .faq-title {
          color: #fff;
          height: 50.14px;
          font-style: normal;
          font-weight: 900;
          font-size: 22px;
          line-height: 27px;
          text-align: center;
        }

        .faq-questions__container {
          width: 100%;
          @include mq("phone-wide", max) {
            width: 335px;
            margin-top: 2em;
          }

          .padding-bottom-12 {
            padding-bottom: 12px !important;
          }
          .faq-question__container {
            //   padding: 23px;
            padding: 23px 40px;
            padding-right: 0px;
            cursor: pointer;
            border-radius: 10px;
            background: #222223;
            @include mq("phone-wide", max) {
              padding: 17px 17px;
              padding-right: 0px;
            }
            // padding: 11px;
            // padding-left: 42px;
            // padding-bottom: 30px;
            margin-bottom: 20px;
            .faq-question__title {
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: flex-start;
              font-family: Montserrat;
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              @include mq("phone-wide", max) {
                font-size: 14px;
              }
              line-height: 22px;
              color: #c8a86b;
              //   padding: 15px 0;

              .faq-arrow {
                cursor: pointer;
                margin-right: 34px;
                img {
                  width: 16px;
                }
                @include mq("phone-wide", max) {
                  margin-right: 16px;
                  img {
                    width: 14px;
                  }
                }

                .faq-arrow__down {
                  transition: 0.3s all;
                  transform: rotate(-180deg);
                }
                .faq-arrow__up {
                  transition: 0.3s all;
                  transform: rotate(-360deg);
                }
              }
            }
            .faq-question__answer-hide {
              display: none;
            }
            .faq-question__answer {
              display: block;
              font-family: Montserrat;
              font-style: normal;
              width: 956px;
              font-weight: 400;
              font-size: 15px;
              line-height: 27px;
              color: #ffffff;
              margin-top: 2px;
              @include mq("phone-wide", max) {
                font-size: 14px;
                line-height: 17px;
                width: 87%;
              }
            }
          }
        }
      }
    }
  }
}

.add-track-uploading-state {
  .upload-title {
    font-size: 22px;
    font-weight: 900;
    line-height: 26px;
    padding-top: 34px;
    padding-bottom: 12px;
    color: #fff;
  }
  .upload-subtitle {
    font-size: 14px;
    color: #aaa;
    font-weight: 400;
  }
}

.selected-file {
  padding-top: 34px;
  display: grid;
  grid-template-columns: 84px 1fr;
  align-items: center;
  margin-bottom: 36px;

  .delete-selected-file {
    cursor: pointer;
    width: 84px !important;
    height: 84px;
    background: rgba($main-3, 0.5);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .selected-file-title {
    font-size: $font-size-22;
    font-weight: $font-weight-4;
    margin-left: 20px;
    word-break: break-all;
  }
  .selected-file-subtitle {
    font-size: 12px;
    margin-left: 20px;
    color: $main-8;
    line-height: 200%;
    padding-top: 4px;
    word-break: break-all;
  }
}
