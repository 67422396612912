@import "../../scss/variables";
@import "../../scss/mixins";

.fourofour-container{
    display: flex;
    justify-content: space-evenly;
    @include mq("desktop", max) {
        justify-content: center;
    }
    flex-direction: column;
    align-items: center;
    height: 50vh;
    .app-store{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .fof-app-store1,
                .fof-google-play1{
                margin: 10px;
                filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.7));
                height: 36px;
                width: 120px;
            }
            display: block;
            @include mq("tablet-small", max) {
                display: none;
            }
        }
    .text-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 9em;
        @include mq("desktop", max) {
            margin-top: 0;
        }
        .text{
            margin: 0;
            margin-bottom: 10px;
            font-style: normal;
            font-weight: bold;
            font-size: 150px;
            line-height: 183px;
            text-align: center;
            letter-spacing: 0.1em;
            background: linear-gradient(180deg, #C8A86B 0%, #725E3A 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            @include mq("tablet-small", max) {
                font-size: 80px;
                line-height: 98px;
                text-align: center;
                letter-spacing: 0.1em;
            }
              &::-moz-selection { /* Code for Firefox */
                -webkit-background-clip: none;
                -webkit-text-fill-color: #fcfcfc;
              }

              &::selection {
            -webkit-background-clip: none;
            -webkit-text-fill-color: #fcfcfc;
              }
        }

        .text-desc{
            margin-bottom:10px;
            width: 540px;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.1em;
            color: #FFFFFF;
            @include mq("tablet-small", max) {
                font-size: 15px;
                line-height: 18px;
                text-align: center;
                letter-spacing: 0.1em;
                width: 100%;
            }

        }
        .magic-btn{
            cursor: pointer;
            outline: none;
            border: none;
            margin:30px 0;
            background: linear-gradient(180deg, #C8A86B 0%, #725E3A 100%);
            box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.5);
            border-radius: 10px;
            span{
                padding: 10px;
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
                display: flex;
                align-items: center;
                text-align: center;
                letter-spacing: 0.15em;
                color: #FFFFFF;

                @include mq("tablet-small", max) {
                    font-size: 15px;
                    line-height: 18px;
                }
            }
        }
    }
    .mobile-footer{
        .mobile-footer__container{
            position: absolute;
            bottom: 0;
            right: -1px;
        }
    }
}
