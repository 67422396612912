@import "../../scss/variables";
@import "../../scss/mixins";

.image-section {
  height: 50px;
  text-align: center;
  .feli-img {
    width: 100px;
    height: 100px;
    position: relative;
    z-index: 100;
  }
}
.f-modal-wrapper {
  background: #222223;
  width: 974px;
  max-width: 95vw;
  text-align: center;
  border-radius: 6px;
  height: calc(100vh - 100px);
  overflow-y: hidden;
  position: relative;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #222223;
  }

  ::-webkit-scrollbar-thumb {
    background: #c8a86b;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #c8a86b;
  }

  .close {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }

  h1.title {
    margin-top: 83px;
    font-size: 25px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 33px;
  }

  .content {
    padding: 0 180px;
    @media (max-width: 800px) {
      padding: 0 20px;
    }
    p {
      color: #fff;
      text-align: left;
      font-size: 16px;
    }
  }
}

.btn-grow-fanbase {
  margin: 40px 0 50px 0;
  width: 342px;
  height: 55px;

  background: #c8a86b;
  border-radius: 6px;
  border: 0;

  font-weight: 600;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  color: #18181a;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    width: 40px;
    margin-left: 10px;
  }
}

// Side drawer css from here
.side-drawer-wrapper {
  width: 350px;
  padding: 32px;
  height: 100%;
  position: relative;

  .sidebar-footer {
    position: absolute;
    bottom: 32px;
    left: 0;
    right: 0;
    text-align: center;
    img {
      width: 84px;
    }
    .sidebar-tagline {
      margin-top: 20px;
      color: #c8a86b;
    }

    .company-intro {
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: #c8a86b;
      margin-bottom: 30px;
    }

    .store-logo {
      padding: 0 20px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
      margin-bottom: 42px;
      img {
        width: 100%;
      }
    }
  }

  @media (max-width: 650px) {
    width: 100%;
  }

  .close {
    text-align: right;
    img {
      width: 24px;
      cursor: pointer;
    }
  }

  .controls {
    margin-top: 80px;

    &.show-close {
      margin-top: 40px;
    }
  }

  .btn-login {
    color: #000;
    font-weight: 600;
    width: 100%;
  }

  .btn-join {
    margin-top: 20px;
    color: #fff;
    background: transparent;
    font-weight: 600;
    width: 100%;
  }
}

.how-it-works {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 120px;
  padding-bottom: 120px;
  text-align: center;
  max-width: calc(100vw - 40px);

  @media (max-width: 767px) {
    display: block;
  }

  & > img {
    width: 350px;
    margin-right: 40px;
    @media (max-width: 767px) {
      width: calc(100vw - 40px);
      margin-right: 0px;
    }
  }

  .work-title {
    font-weight: 900;
    font-size: 36px;
    text-align: center;
    color: #c8a86b;
    margin-bottom: 24px;
    @media (max-width: 767px) {
      font-size: 33px;
      margin-top: 40px;
    }
  }
  .works-desc {
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    text-align: center;
    color: #ffffff;
    max-width: 574px;
    @media (max-width: 767px) {
      font-size: 20px;
      max-width: unset;
    }
  }
}
.more-info {
  text-align: center;
  max-width: calc(100vw - 40px);
  margin: auto;
  border-bottom: 0.5px solid #c8a86b;
  border-top: 0.5px solid #c8a86b;
  padding: 100px 0;

  .more-info-title {
    font-size: 36px;
    color: #c8a86b;
    font-weight: 900;
    margin-bottom: 40px;
    @media (max-width: 767px) {
      font-size: 33px;
    }
  }

  .more-info-desc {
    display: inline-block;
    font-weight: 900;
    font-size: 36px;
    line-height: 65px;
    text-align: center;
    color: #ffffff;
    width: 987px;
    max-width: calc(100vw - 40px);
    @media (max-width: 767px) {
      font-size: 25px;
      line-height: 30px;
    }
  }

  &.dream {
    margin-top: 120px;
    .more-info-desc {
      width: 520px;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      @media (max-width: 767px) {
        font-size: 20px;
        line-height: 29px;
      }
    }
  }
}

.success-path {
  max-width: calc(100vw - 40px);
  margin: auto;
  padding-top: 100px;
  text-align: center;
  .path-title {
    font-size: 36px;
    color: #c8a86b;
    font-weight: 900;
    @media (max-width: 767px) {
      font-size: 33px;
    }
  }
  .path-desc {
    margin-top: 40px;
    display: inline-block;
    max-width: 700px;
    font-weight: 500;
    font-size: 18px;
    line-height: 29px;
    color: #fff;
    @media (max-width: 767px) {
      font-size: 20px;
    }
  }
  .path-img {
    margin-top: 60px;
    img {
      width: 834px;
      max-width: calc(100vw - 40px);
      &.web {
        @media (max-width: 767px) {
          display: none;
        }
      }
      &.mob {
        display: none;
        @media (max-width: 767px) {
          display: inline-block;
        }
      }
    }
  }
}
section.subscribe-section-mobile {
  margin: 120px 0 64px 0;
  color: #fff;
  padding: 0 40px;

  .sbox-wrap {
    // border: 1px solid #c8a86b;
    border-radius: 12px;
    text-align: center;
    // padding: 24px;

    img {
      max-width: 90%;
    }
  }

  .subscribe-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 29px;
    margin-bottom: 24px;
  }

  .exclusive-desc {
    font-size: 14px;
    font-weight: 400;
    font-style: italic;
    margin-bottom: 24px;
    line-height: 29px;
  }

  .sub-email {
    width: 100%;
  }

  .btn-listen {
    margin-top: 16px;
    width: 100%;
  }
}
input.sub-email {
  height: 44px;
  background: #18181a;
  flex: 1;
  margin-right: 20px;
  font-size: 16px;
  border: 0.5px solid #c8a86b;
  padding: 0 16px;
  border-radius: 4px;
  color: #fff;
  outline: none;
}
.btn-listen {
  background: #c8a86b;
  color: #000;
  height: 44px;
  width: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 4px;
  cursor: pointer;
}
.actions {
  margin-top: 24px;
}
section.subscribe-section-desktop {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 120px 0 64px 0;

  .subscribe-image-mob {
    max-width: 400px;
  }

  .sbox-wrap {
    margin-right: 100px;
  }

  .subscribe-box {
    max-width: 640px;
    border: 1px solid #c8a86b;
    border-radius: 20px;
    padding: 80px;
    color: #fff;
    .subscribe-title {
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: 10px;
    }

    .subscribe-tagline {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 10px;
      text-align: center;
    }

    .exclusive-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 24px;
      .exclusive-desc {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 10px;
        text-align: center;
      }
      img {
        margin-right: 24px;
        height: 60px;
      }
    }
  }
}

button.get-started {
  margin-top: 60px;
  background: #c8a86b;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  color: #000;
  border: 0;
  width: 220px;
  height: 55px;
  cursor: pointer;
}
