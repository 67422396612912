.hamburger {
  cursor: pointer;
  position: relative;
  right: 20px;
  z-index: 999999;
  transition: transform 1s;
}
.to-left {
  transform: translateX(150px);
  @media (max-width: 750px) {
    transform: translateX(0px);
  }
}
header.nav-wrap {
  padding: 0 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 24px;

  @media (max-width: 767px) {
    padding: 0 20px;
    padding-top: 24px;
  }

  .links{
    color: #fff;
    ul{
      list-style: none;
      padding: 0;
      li{
        display: inline-block;
        padding: 0 20px;
        a{
          font-weight: 700;
          font-size: 16px;
          color: #fff;
        }
      }
    }
  }

  .logo {
    display: flex;
    align-items: center;
    img {
      width: 77px;
      @media (max-width: 767px) {
        width: 64px;
      }
    }
    .tagline {
      margin: 0 0 0 34px;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      color: #c8a86b;

      @media (max-width: 767px) {
        display: none;
      }
    }
  }
  img.menu {
    width: 30px;
    cursor: pointer;
  }
}

section.footer {
  padding-top: 128px;
  padding-bottom: 80px;
  text-align: center;
  position: relative;
  background: #18181a;

  .footer-wrap {
    margin: auto;
    max-width: calc(100vw - 40px);
  }

  .footer-menu {
    position: absolute;
    right: 150px;
    top: 154px;
    @media (max-width: 767px) {
      right: 20px;
    }
    img {
      width: 30px;
      cursor: pointer;
    }
  }

  .logo {
    width: 84px;
  }
  .copy-right {
    margin-top: 24px;
    color: #fff;
  }
  .icons {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    a {
      margin-right: 16px;
      width: 30px;
      &:last-child {
        margin-right: 0px;
      }
    }
    img {
      height: 30px;
    }
  }
  ul.footer-list {
    color: #fff;
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      margin-top: 16px;
      display: inline-block;
      margin-right: 30px;
      padding-right: 30px;
      border-right: 1px solid #fff;
      font-size: 14px;
      &:last-child {
        border-right: none;
        margin-right: 0px;
        padding-right: 0px;
      }
      a {
        color: #fff;
        text-decoration: underline;
      }
    }
  }
}

.scrollbar-container > div {
  overflow-x: hidden !important;
}
