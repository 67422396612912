@import "../../scss/variables.scss";

.search-bar-container {
  height: 70px;
  width: 100%;
  border-bottom: 1px solid $main-12;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .search-input-container {
    width: 278px;
    height: 44px;
    background: $main-10;
    border-radius: 6px;
    margin-left: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 19px;

    .search-input {
      width: 100%;
      margin-left: 15px;
      background: $main-10;
      border: none;
      outline: none;
      color: $main-1;
    }
  }
}

@media (max-width: 650px) {
  .search-bar-container {
    justify-content: center;

    .search-input-container {
      margin-left: unset;
      width: calc(100% - 80px);
    }
  }
}

.influencer-main-container {
  display: flex;
  padding: 40px;
  padding-bottom: 0px;
  flex-direction: column;
  overflow: auto;
  height: calc(100% - 225px);

  .influencer-header {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    margin-bottom: 25px;
  }

  .influencer-section {
    display: flex;
    flex-wrap: wrap;

    .influencer-profile-container {
      width: 270px;
      height: 270px;
      background: $main-10;
      border-radius: 6px;
      margin: 20px 0px;
      margin-right: 25px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .name-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
        text-align: center;
      }

      .influencer-profile {
        width: 134px;
        height: 134px;
        border-radius: 120px;
        object-fit: cover;
      }

      .profile-name-container {
        display: flex;
      }

      .influencer-profile-name {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: $font-size-l;
        line-height: 22px;
        color: $main-1;
        margin-right: 10px;
      }

      .listener-roles {
        font-family: Montserrat;
        font-size: $font-size-small;
        line-height: 15px;
        color: $main-1;
        opacity: 0.5;
        margin-top: 5px;
        text-transform: capitalize;
      }
    }
  }

  .empty-search-container {
    height: calc(100vh - 280px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .empty-search-sub-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .no-result-txt {
      font-family: Montserrat;
      font-size: $font-size-normal;
      line-height: 17px;
      text-align: center;
      color: $main-5;
      margin-top: 15px;
    }
  }
}

@media (max-width: 650px) {
  .influencer-main-container {
    padding: 25px;
    height: calc(100% - 195px);

    .influencer-header {
      margin-bottom: 15px;
      width: 265px;
      align-self: center;
    }

    .influencer-section {
      justify-content: center;

      .influencer-profile-container {
        margin: 10px 0px;
        margin-right: unset;
      }
    }
  }
}

.influencer-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5rem;

  svg {
    color: #c8a86b;
  }

  .load-more-button {
    color: black;
  }
}


