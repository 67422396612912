@import "../../scss/variables.scss";
@import "../../scss/mixins";

.enrollment {
  height: 115vh;
  background: #18181a;

  .container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .success-wrapper {
      // @include mq("phone-wide", max) {
      //     width: 300px;
      // }
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .received-text__container {
        width: 913px;
        margin-top: 5em;
        @include mq("phone-wide", max) {
          width: 270px;
        }
        font-family: Montserrat;
        font-style: normal;
        text-align: center;
        h1 {
          font-family: Clicker Script;
          font-style: normal;
          font-weight: normal;
          font-size: 60px;
          line-height: 151.9%;
          text-align: center;
          color: #ffffff;
          @include mq("phone-wide", max) {
            font-weight: normal;
            font-size: 30px;
            line-height: 160%;
            text-align: center;
          }
        }
        .desc-2 {
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 151.9%;
          text-align: center;
          color: #ffffff;
          width: 100%;
          @include mq("phone-wide", max) {
            font-weight: normal;
            font-size: 18px;
            line-height: 160%;
            text-align: center;
          }
        }
      }
      .footer-container {
        margin-top: 5em;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .breaker-quote {
          font-family: Montserrat;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;
          display: flex;
          align-items: center;
          text-align: center;
          color: #c8a86b;
          text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
          @include mq("phone-wide", max) {
            font-size: 15px;
            line-height: 18px;
          }
        }
        .mobile-app {
          margin-top: 2em;
          display: flex;
          justify-content: space-between;
          width: 298px;
          align-items: center;
          a {
            img {
              width: 144px;
            }
          }
        }
        .logo-container {
          margin-top: 3em;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .logo {
            width: 77px;
            height: 66px;
          }
          .sidebar-tagline {
            margin-top: 2em;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            color: #c8a86b;
          }
        }
      }
    }
  }
}
