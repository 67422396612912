@import "../../../scss/variables.scss";

.track-info-card {
  background: $main-10;
  text-align: center;
  padding: 34px 24px;
  height: 270px;
  width: 270px;
  margin-right: 20px;
  margin-bottom: 20px;
  border-radius: 6px;
  box-sizing: border-box;
  @media (max-width: 670px) {
    width: 100%;
  }

  &:last-of-type {
    margin-right: 0;
  }

  &-count {
    font-size: $font-size-44;
    font-weight: $font-weight-4; //var use
    padding: 14px;
  }
  &-title {
    color: $main-2;
    text-transform: uppercase;
    font-size: $font-size-normal;
    font-weight: $font-weight-1;
    padding-bottom: 24px;
  }

  &-description {
    font-size: $font-size-small;
    font-weight: 300;
    line-height: 20px;
    color: rgba($main-1, 0.5);
  }
}
