@import "../../scss/variables.scss";

.register-main-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 25px 35px;
  max-width: 400px;
  color: $main-1;
  .close-icon {
    margin-bottom: 45px;
    min-height: 30px;
  }
  .error-txt {
      color: $main-8;
  }
  .register-second-container {
    max-width: 300px;
    .register-header {
      font-size: $font-size-22;
    }
    .register-form {
      padding-top: 25px;
    }
    .register-input-container {
      margin-top: 30px;
      .error-border {
          border: 1px solid $main-8;
      }
      .input-label {
        color: $main-2;
        font-size: $font-size-small;
      }
      .register-input {
        width: calc(100% - 15px);
        height: 44px;
        left: 414px;
        top: 508px;
        background: $main-3;
        border-radius: 8px;
        color: $main-1;
        margin-top: 10px;
        border: none;
        padding-left: 15px;
        outline: none;
      }
    }
    .register-button-container {
      background: $main-2;
      border: 1px solid $main-2;
      box-sizing: border-box;
      border-radius: 8px;
      color: $main-3;
      font-weight: $font-weight-1;
      font-size: $font-size-normal;
      line-height: 24px;
      text-align: center;
      text-transform: uppercase;
      width: 100%;
      height: 50px;
      margin-top: 35px;
      outline: none;
      max-width: 350px;
    }
    .sms-message-span {
      font-size: $font-size-normal;
      line-height: $font-size-17;
      color: $main-1;
      opacity: 0.5;
      padding-left: 10px;
      max-width: calc(100% - 40px);
    }
    .sms-info-container {
      padding: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 340px;
      margin-top: 20px;
    }
    .breaker-logo-style {
      height: 90px;
    }
  }
}
