@import "../../scss/variables.scss";

.room_preview {
  background: $main-9;
  flex: 1;

  .header {
    border-bottom: 1px solid $main-17;
    padding: 0.5rem 1rem;

    .header_logo {
      height: 30px;
    }
    .header_link {
      font-style: normal;
      font-weight: $font-weight-1;
      font-size: $font-size-small;
      line-height: 9px;
      letter-spacing: 0.06em;
      text-transform: uppercase;
      text-decoration: none;
      color: $main-2;
    }
  }

  .content {
    padding: 1rem 0;

    .wrapper {
      max-width: 375px;
      padding: 0 1rem;
      margin: 0 auto;

      .imgWrapper {
        border-radius: 6px;
        width: 100%;
        height: calc(375px - 2em);
        background-size: cover;
        position: relative;
        display: flex;
        align-items: flex-end;

        .roomTitle {
          font-size: $font-size-medium;
          font-weight: $font-weight-1;
          line-height: 17px;
          color: $main-1;
          margin-bottom: 2rem;
        }
        .roomAuthor {
          font-size: $font-size-15;
          line-height: 15px;
          color: $main-1;
          opacity: 0.8;
        }
        .roomStartTime {
          font-size: $font-size-medium;
          line-height: 15px;
          color: $main-2;
          opacity: 0.8;
        }
        .bottomFade {
          position: absolute;
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 6px;
          width: 100%;
          bottom: 0;
          left: 0;
          right: 0;
          height: 30%;
          background: linear-gradient(360deg, $main-4 0%, rgba(24, 24, 26, 0) 100%);
        }
      }
      .subtitle {
        font-size: $font-size-medium;
        line-height: 15px;
        color: $main-2;
        margin-bottom: 2rem;
      }
      .coHosts {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 2rem;

        .userPic {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
      }
      .roomDescription {
        font-weight: $font-weight-4;
        font-size: $font-size-medium;
        line-height: 22px;
        text-align: center;
        color: $main-1;
      }
      .appHint {
        border-top: 1px solid $main-17;
        padding-top: 1rem;

        p {
          font-size: $font-size-small;
          line-height: 22px;
          text-align: center;
          color: $main-2;
          margin-top: 1rem;
          margin-bottom: 1rem;
        }
        .store-button {
          cursor: pointer;
          margin: 1rem auto;
          display: block;
        }
      }
    }
  }
}