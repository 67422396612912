@import "../.././../scss/variables.scss";

.fan-roles-container {
  min-width: 350px;
  height: 100vh;
  background: $main-10;
  color: $main-1;
  display: flex;
  flex-direction: column;

  .fan-roles-header-container {
    padding: 27px 34px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .fan-roles-main-container {
    padding: 0 34px;
    padding-top: 0px;
    min-height: max-content;
    height: calc(100vh - 85px);
    position: relative;
    overflow-y: auto;

    .fan-role-done {
      min-width: unset;
      width: 100%;
      height: 44px;
      margin-bottom: 34px;
      font-size: 12px;
      font-weight: 600;
      cursor: pointer;
    }

    .fan-roles-title {
      padding: 12px 0;
      font-size: $font-size-22;
      font-style: normal;
      font-weight: $font-weight-1;
      padding-bottom: 34px;
    }

    .fan-subroles-list {
      .fan-subrole-item {
        display: flex;
        padding-bottom: 24px;
        margin-bottom: 24px;
        border-bottom: 1px solid;
        border-color: $main-17;

        .fan-subrole-item-name {
          padding-left: 24px;
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
        }
      }
    }
  }
}
