* {
  font-family: -apple-system, BlinkMacSystemFont, "Montserrat", sans-serif;
}
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: black;
}
.mainContainer {
  min-height: 100vh;
}

.scrollbar__track__vertical {
  right: 0px;
  top: 0;
  bottom: 0;
  width: 2px;
  position: absolute;
  background-color: transparent;
  transition: 0.2s;
  border-radius: 2px !important;

  &:hover {
    width: 4px;
  }
}

.h-mob {
  @media (max-width: 767px) {
    display: none !important;
  }
}

.s-mob {
  @media (min-width: 768px) {
    display: none !important;
  }
}

.flex {
  display: flex;
  &.jcsb {
    justify-content: space-between;
  }
  &.jcc {
    justify-content: center;
  }
  &.jcr {
    justify-content: flex-end;
  }
  &.ci {
    align-items: center;
  }
}
