@import "../.././../scss/variables.scss";

.edit-track-container {
  width: 350px;
  height: 100vh;
  background: $main-10;
  color: $main-1;
  display: flex;
  flex-direction: column;

  @media (max-width: 650px) {
    width: 100%;
  }

  .edit-track-header-container {
    padding: 27px 34px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .edit-track-main-container {
    padding: 0 34px;
    padding-top: 0px;
    min-height: max-content;
    height: calc(100vh - 85px);
    position: relative;
    overflow-y: auto;

    .edit-track-title {
      padding: 12px 0;
      font-size: $font-size-22;
      font-style: normal;
      font-weight: $font-weight-1;
    }

    .edit-track-inputs {
      .edit-track-input-title {
        font-size: 12px;
        font-weight: 400;
        color: $main-2;
        padding-top: 24px;
      }
      .edit-track-input-field {
        background: $main-11;
        border-radius: 6px;
        border: 0;
        color: $main-1;
        padding: 12px;
        width: calc(100% - 24px);
        margin-top: 12px;
      }

      .file-dropper {
        padding-top: 40px;

        &.error {
          .file-dropper-wrapper {
            border-color: $main-8;
          }
        }

        &-title {
          font-size: $font-size-medium;
          font-weight: $font-weight-4;
          line-height: 32px;
          span {
            color: $main-2;
            font-weight: $font-weight-1;
          }
        }

        &-subtitle {
          font-size: $font-size-small;
          font-weight: 300;
          color: rgba($main-1, 0.5);
          line-height: 24px;
          span {
            color: $main-1;
            font-weight: $font-weight-1;
          }
        }
      }
      .file-dropper-wrapper {
        border: 1px dashed;
        border-color: rgba($main-2, 0.5);
        height: 180px;
        padding: 16px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .edit-track-author-details {
        width: 278px;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: $main-5;
        padding-top: 12px;
        padding-bottom: 10px;
      }
      .edit-track-thumb-image-container {
        position: relative;
        .delete-icon {
          cursor: pointer;
          position: absolute;
          top: 36px;
          right: 24px;
          width: 34px;
          height: 34px;
          border-radius: 50%;
          background: $main-11;
          display: flex;
          justify-content: center;
          align-items: center;

          .delete-icon-element {
            width: 14px;
          }
        }
      }
      .edit-track-thumb-image {
        width: 100%;
        height: 284px;
        margin-top: 12px;
        border-radius: 6px;
        object-fit: cover;
      }
    }
  }
  .edit-track-genre {
    padding-top: 34px;
    padding-bottom: 24px;
    .genre-item-list {
      width: 100%;

      margin-top: 24px;

      .genre-item {
        cursor: pointer;
        min-width: 72px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background: $main-11;
        border-radius: 8px;
        padding: 8px 20px;
        margin-right: 16px;
        margin-bottom: 16px;
        font-weight: 400;
        font-size: 14px;
        line-height: 200%;
        border: 1px solid transparent;

        &.is-selected {
          border: 1px solid $main-2;
        }

        .count {
          margin-left: 8px;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background: $main-2;
          color: #000;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }
  .edit-track-save-button {
    width: 100%;
    height: 44px;
    background: $main-2;
    border-radius: 6px;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 16px;
    cursor: pointer;
  }

  .edit-track-delete-button {
    width: 100%;
    height: 44px;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: $main-8;
    background: transparent;
    cursor: pointer;
    margin-bottom: 16px;
  }
}
