@import "../../../../scss/variables.scss";

.payment-tab-container {
  width: 903px;
  margin: 0 auto;
  max-width: calc(100% - 80px);

  .cancel-pro-subscription {
    margin-top: 24px;
    background: $main-11;
    border-radius: 6px;
    padding: 24px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    margin-bottom: 44px;

    @media (max-width: 670px) {
      padding: 24px 40px;
    }

    .cancel-pro-title {
      font-size: $font-size-small;
      font-weight: $font-weight-4;
      padding-bottom: 6px;
    }
    .cancel-pro-amount {
      font-size: $font-size-22;
      font-weight: $font-weight-1;
      line-height: 27px;
    }

    .cancel-pro-button {
      min-width: 254px;
      height: 44px;
      font-weight: $font-weight-1;
      font-size: $font-size-small;
      border: 1px solid $main-2;
      background-color: transparent;
      color: $main-1;
      @media (max-width: 670px) {
        min-width: 120px;
      }
    }
  }

  .active-plan-title {
    font-size: $font-size-normal;
    line-height: 200%;
    color: $main-2;
    margin-top: 24px;
  }

  .payment-tab-method-container {
    border-bottom: 1px solid $main-12;
    .title {
      padding-top: 34px;
      font-size: 22px;
      font-weight: 600;
    }
    .header-container {
      margin: 40px 0px;
    }
    .payment-receive-header {
      font-size: $font-size-normal;
      line-height: 200%;
      color: $main-2;
      margin-bottom: 20px;
    }
    .card-detail-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 15px 0px;
      .card-detail-delete-icon {
        cursor: pointer;
      }
      .card-last-digits {
        margin-left: 25px;
      }
      .default-txt {
        font-size: $font-size-small;
        margin-right: 15px;
      }
      .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
        background-color: $main-2;
      }
    }
  }
  .add-tab-another-container {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $main-12;
    padding: 40px 0px;
    .add-another-btn {
      width: 278px;
      height: 44px;
      background: $main-11;
      border-radius: 6px;
      color: $main-1;
    }
  }
}

.all-header-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  .toggle-switch {
    display: flex;
    align-items: center;

    .switch-component {
      padding: 0 10px;
    }

    p {
      margin: 0;
      font-weight: 500;
      font-size: 14px;
    }
  }
}

.all-membership-title {
  color: $main-2;
  font-size: 14px;
  margin-top: 16px;
}

.membership-item {
  padding: 24px;
  background: $main-11;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  .plan-name {
    font-size: 12px;
  }

  .plan-cost {
    font-size: 22px;
    font-weight: 600;
  }

  .btn-select {
    background: transparent;
    border: 1px solid $main-2;
    font-size: 12px;
    font-weight: 600;
  }

  .btn-selected {
    background: #7551e9;
    font-size: 12px;
    font-weight: 600;
  }
}

.subscription-modal {
  border: 0.5px solid $main-2;
  width: 520px;
  max-width: 95vw;
  border-radius: 6px;
  padding: 20px;
  background: #222223;

  .close {
    display: flex;
    justify-content: flex-end;
    img {
      cursor: pointer;
    }
  }

  .sub-title {
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    margin-top: 24px;
  }

  .plan-name {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    color: $main-2;
    text-align: center;
    margin-top: 24px;
  }

  .btn-selected {
    background: #7551e9;
    font-size: 12px;
    color: #fff;
    font-weight: 600;
  }

  .controls {
    margin: 24px 0;
    text-align: center;
  }

  .success-img {
    margin: 24px 0;
    text-align: center;
    img {
      height: 100px;
    }
  }

  .stripe-el {
    background: $main-11;
    margin: 10px 0;
    border-radius: 4px;
    padding: 10px;
  }

  .el-title {
    font-size: 12px;
    color: $main-2;
  }

  .el-flex {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
  }
}
